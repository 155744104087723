import { gpuServer } from "../http-common";

class FileUploadService4VideoBackgroundEditor {
  upload(files, action, location, foregroundTransparency, resultSizeType, sid, locale, onUploadProgress) {
    let formData = new FormData();
    files.forEach(f => {
      formData.append("file", f);
    });
    formData.append("action", action);
    formData.append("location", location);
    formData.append("foregroundTransparency", foregroundTransparency);
    formData.append("resultSizeType", resultSizeType);
    formData.append("sid", sid);
    formData.append("locale", locale);

    return gpuServer.post("/upload4VideoBackgroundEditor", formData, {  
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
      timeout: 30 * 60 * 1000,
    });
  }

  getFiles(path) {
    return gpuServer.get(`/files/${path}`);
  }
}

export default new FileUploadService4VideoBackgroundEditor();