import { cpuServer, gpuServer } from "../http-common";

class FileUploadService4VideoEnhancer {
  server = cpuServer;

  async setupServer() {
    const res = await cpuServer.get("/checkGpuServerStatus");
    if (res.data.isServerAvailable > "0" && res.data.hasRunningJob < "1")
        this.server = gpuServer;
  }

  upload(files, type, resultSizeType, sid, locale, onUploadProgress) {
    let formData = new FormData();
    files.forEach(f => {
      formData.append("file", f);
    });
    formData.append("type", type);
    formData.append("resultSizeType", resultSizeType);
    formData.append("sid", sid);
    formData.append("locale", locale);

    return this.server.post("/upload4VideoEnhancer", formData, {  
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
      timeout: 30 * 60 * 1000,
    });
  }

  getFiles(path) {
    if (path)
      return this.server.get(`/files/${path}`);
  }
}

export default new FileUploadService4VideoEnhancer();