import React, { useContext, useState } from "react";
import { motion } from "framer-motion";
import parse from "html-react-parser";
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import intl from 'react-intl-universal';
import { Helmet } from 'react-helmet-async';

import { Context } from "../Context";
import { APP_NAME, HELP_VIDEO_OPTIONS } from "../constants";
import { getSpecialMessageHTML } from "../utils";

const VideoPlusStudio = (props) => {
  const { dynamicText, authenticated, user } = useContext(Context);

  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState('tab1');
  
  // Toggle active state for Tab
  const toggleTab = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  }

  const ShowNavItem = ({ tabId, title }) => {
    return (
      <NavItem>
        <NavLink className={classnames("flex-md-fill text-md-center", {active: currentActiveTab === tabId})} onClick={() => { toggleTab(tabId); }}>
          <h4>{title}</h4>  
        </NavLink>
      </NavItem>        
    )
  }

  const [selectedVideo, setSelectedVideo] = useState(HELP_VIDEO_OPTIONS[0]);
  const onSelectVideo = event => {
    const selectedOption = HELP_VIDEO_OPTIONS.find(el => el.key === event.target.value);
    setSelectedVideo(selectedOption || HELP_VIDEO_OPTIONS[0]);
  } 

  const formGroupClassName = props.isLargeScreen ? "form-group row m-4" : "form-group row m-2";
  const specialMessage = getSpecialMessageHTML(authenticated, user, dynamicText);
  return (
    <motion.div className="content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Helmet>
        <title>{APP_NAME} { intl.get('Videoplus_Studio') }</title>
        <meta name="description" content={ intl.get('Videoplus_Studio_headline') } />
      </Helmet>
      <div className="title">{ intl.get('Videoplus_Studio') }</div>
      {specialMessage && (
        <div className="alert alert-danger mt-2">
          {parse(specialMessage)}
        </div>
      )}

      {props.isLargeScreen && (
        <div className="row mx-1 my-4">
          <div className="col-4 align-self-center text-center">
            <p align="left"><h3><font color='#556B2F'>{ intl.get('Videoplus_Studio_headline') }</font></h3></p>
            <a className="btn btn-info btn-lg" href="https://videoplus.studio/app" target="_blank" rel="noreferrer">{ intl.get('Videooplus_Studio_button_text') }</a>
            {/* <p/><p align="left">{ intl.getHTML('Videooplus_Studio_specials') }</p> */}
          </div>
          <div className="col-8 text-center">
            <select className="selectpicker" data-style="btn-success" onChange={onSelectVideo}>
              {HELP_VIDEO_OPTIONS.map(item => {
                return (<option key={item.key} value={item.key} defaultValue={selectedVideo.key}>{item.title}</option>);
              })}
            </select><p/>
            {selectedVideo && (
              <div className="embed-responsive embed-responsive-16by9"> 
                <iframe width="560" height="315" src={selectedVideo.url} title={selectedVideo.title} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            )}
          </div>
        </div>
      )}
      {!props.isLargeScreen && (
        <div className="row m-1">
          <div className="col-12 align-self-center text-center">
            <p align="left"><h3><font color='#556B2F'>{ intl.get('Videoplus_Studio_headline') }</font></h3></p>
            <a className="btn btn-info btn-lg" href="https://videoplus.studio/app" target="_blank" rel="noreferrer">{ intl.get('Videooplus_Studio_button_text') }</a><p/>
            {/* <p/><p align="left">{ intl.getHTML('Videooplus_Studio_specials') }</p> */}
            <select className="selectpicker" data-style="btn-success" onChange={onSelectVideo}>
              {HELP_VIDEO_OPTIONS.map(item => {
                return (<option key={item.key} value={item.key} defaultValue={selectedVideo.key}>{item.title}</option>);
              })}
            </select><p/>
            {selectedVideo && (
              <div className="embed-responsive embed-responsive-16by9"> 
                <iframe width="560" height="315" src={selectedVideo.url} title={selectedVideo.title} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            )}
          </div>
        </div>
      )}

      <div className={formGroupClassName} />
      <div className="m-3 text-center">
        <h4>{ intl.get('Videoplus_Studio_subline') }</h4>
      </div>
      <div className="m-3 text-center">
        <h5>{ intl.get("Videoplus_Studio_highlights", { 'count_languages': 80, 'count_voices': 458, 'count_avatars': 528 }) }</h5>
      </div>
      <div>
        <Nav pills fill className={"flex-column flex-xl-row"} style={{backgroundColor: "lightblue"}}>
          <ShowNavItem tabId={'tab1'} title={ intl.get('Product_Service_Demos') } />
          <ShowNavItem tabId={'tab2'} title={ intl.get('Sales_Marketing') } />
          <ShowNavItem tabId={'tab3'} title={ intl.get('Podcast_Helper') } />
          <ShowNavItem tabId={'tab4'} title={ intl.get('Video_Transformer') } />
          <ShowNavItem tabId={'tab5'} title={ intl.get('Storybook_Maker') } />
          {/*<ShowNavItem tabId={'tab6'} title={ t('Multilingual_More') } />*/}
        </Nav>
        
        <TabContent activeTab={currentActiveTab}>
          <TabPane tabId="tab1">
            <div className="card">
              <div className="row m-2">
                <div className="col-12 introduction">
                  { intl.getHTML('Product_Service_Demos_description') }
                </div>
              </div>

              <div className="row m-2">
                <div className="col-lg-6">
                  <div className="embed-responsive embed-responsive-16by9"> 
                    <LazyLoadComponent>
                      <iframe width="560" height="315" src={dynamicText.videoplusStudioVideo} title={ intl.get('Videooplus_Studio_demo_video') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
                    </LazyLoadComponent>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="embed-responsive embed-responsive-16by9"> 
                    <LazyLoadComponent>
                      <iframe width="560" height="315" src={dynamicText.videoplusStudioVideo2} title={ intl.get('Videooplus_Studio_demo_video') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
                    </LazyLoadComponent>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>

          <TabPane tabId="tab2">
            <div className="card">
              <div className="row m-2">
                <div className="col-12 introduction">
                  { intl.get('Sales_Marketing_description') }
                </div>
              </div>

              <div className="row m-2">
                <div className="col-lg-6">
                  <div className="embed-responsive embed-responsive-16by9"> 
                    <LazyLoadComponent>
                      <iframe width="560" height="315" src={dynamicText.videoplusStudioVideo3} title={ intl.get('Videooplus_Studio_demo_video') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
                    </LazyLoadComponent>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="embed-responsive embed-responsive-16by9"> 
                    <LazyLoadComponent>
                      <iframe width="560" height="315" src={dynamicText.videoplusStudioVideo4} title={ intl.get('Videooplus_Studio_demo_video') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
                    </LazyLoadComponent>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>

          <TabPane tabId="tab3">
            <div className="card">
              <div className="row m-2">
                <div className="col-12 introduction">
                  { intl.get('Podcast_Helper_description') }
                </div>
              </div>

              <div className="row m-2">
                <div className="col-lg-6">
                  <div className="embed-responsive embed-responsive-16by9"> 
                    <LazyLoadComponent>
                      <iframe width="560" height="315" src={dynamicText.videoplusStudioVideo5} title={ intl.get('Videooplus_Studio_demo_video') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
                    </LazyLoadComponent>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="embed-responsive embed-responsive-16by9"> 
                    <LazyLoadComponent>
                      <iframe width="560" height="315" src={dynamicText.videoplusStudioVideo6} title={ intl.get('Videooplus_Studio_demo_video') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
                    </LazyLoadComponent>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>

          <TabPane tabId="tab4">
            <div className="card">
              <div className="row m-2">
                <div className="col-12 introduction">
                  { intl.get('Video_Transformer_description') }
                </div>
              </div>

              <div className="row m-2">
                <div className="col-lg-6">
                  <div className="embed-responsive embed-responsive-16by9"> 
                    <LazyLoadComponent>
                      <iframe width="560" height="315" src={dynamicText.videoplusStudioVideo9} title={ intl.get('Videooplus_Studio_demo_video') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
                    </LazyLoadComponent>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="embed-responsive embed-responsive-16by9"> 
                    <LazyLoadComponent>
                      <iframe width="560" height="315" src={dynamicText.videoplusStudioVideo10} title={ intl.get('Videooplus_Studio_demo_video') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
                    </LazyLoadComponent>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>

          <TabPane tabId="tab5">
            <div className="card">
              <div className="row m-2">
                <div className="col-12 introduction">
                  { intl.get('Storybook_Maker_description') }
                </div>
              </div>

              <div className="row m-2">
                <div className="col-lg-6">
                  <div className="embed-responsive embed-responsive-16by9"> 
                    <LazyLoadComponent>
                      <iframe width="560" height="315" src={dynamicText.videoplusStudioVideo11} title={ intl.get('Videooplus_Studio_demo_video') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
                    </LazyLoadComponent>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="embed-responsive embed-responsive-16by9"> 
                    <LazyLoadComponent>
                      <iframe width="560" height="315" src={dynamicText.videoplusStudioVideo12} title={ intl.get('Videooplus_Studio_demo_video') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
                    </LazyLoadComponent>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>

          <TabPane tabId="tab6">
            <div className="card">
              <div className="row m-2">
                <div className="col-12 introduction">
                  { intl.get('Multilingual_More_description') }
                </div>
              </div>

              <div className="row m-2">
                <div className="col-lg-6">
                  <div className="embed-responsive embed-responsive-16by9"> 
                    <LazyLoadComponent>
                      <iframe width="560" height="315" src={dynamicText.videoplusStudioVideo7} title={ intl.get('Videooplus_Studio_demo_video') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
                    </LazyLoadComponent>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="embed-responsive embed-responsive-16by9"> 
                    <LazyLoadComponent>
                      <iframe width="560" height="315" src={dynamicText.videoplusStudioVideo8} title={ intl.get('Videooplus_Studio_demo_video') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
                    </LazyLoadComponent>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </div>

      <div className={formGroupClassName} />
      <div className="container-fluid text-center">
        <h4>{ intl.get('More_Use_Cases') }</h4>
        <br/>
        <div className="row">
          <div className="col-md-6">
            <h5>{ intl.get('Use_Case3_title') }</h5>
            <p align="left">{ intl.get('Use_Case3') }</p>
          </div>
          <div className="col-md-6">
            <h5>{ intl.get('Use_Case7_title') }</h5>
            <p align="left">{ intl.get('Use_Case7') }</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h5>{ intl.get('Use_Case8_title') }</h5>
            <p align="left">{ intl.get('Use_Case8') }</p>
          </div>
          <div className="col-md-6">
            <h5>{ intl.get('Use_Case6_title') }</h5>
            <p align="left">{ intl.get('Use_Case6') }</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h5>{ intl.get('Use_Case5_title') }</h5>
            <p align="left">{ intl.get('Use_Case5') }</p>
          </div>
          <div className="col-md-6">
            <h5>{ intl.get('Use_Case1_title') }</h5>
            <p align="left">{ intl.get('Use_Case1') }</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h5>{ intl.get('Use_Case2_title') }</h5>
            <p align="left">{ intl.get('Use_Case2') }</p>
          </div>
          <div className="col-md-6">
            <h5>{ intl.get('Use_Case4_title') }</h5>
            <p align="left">{ intl.get('Use_Case4') }</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h5>{ intl.get('Use_Case9_title') }</h5>
            <p align="left">{ intl.get('Use_Case9') }</p>
          </div>
          <div className="col-md-6">
            <h5>{ intl.get('Use_Case10_title') }</h5>
            <p align="left">{ intl.get('Use_Case10') }</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h5>{ intl.get('Use_Case11_title') }</h5>
            <p align="left">{ intl.get('Use_Case11') }</p>
          </div>
          <div className="col-md-6">
            <h5>{ intl.get('Use_Case12_title') }</h5>
            <p align="left">{ intl.get('Use_Case12') }</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h5>{ intl.get('Use_Case13_title') }</h5>
            <p align="left">{ intl.get('Use_Case13') }</p>
          </div>
          <div className="col-md-6">
            <h5>{ intl.get('Use_Case14_title') }</h5>
            <p align="left">{ intl.get('Use_Case14') }</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h5>{ intl.get('Use_Case15_title') }</h5>
            <p align="left">{ intl.get('Use_Case15') }</p>
          </div>
          <div className="col-md-6">
            <h5>{ intl.get('Use_Case16_title') }</h5>
            <p align="left">{ intl.get('Use_Case16') }</p>
          </div>
        </div>
      </div>
    </motion.div>
    );
};

export default VideoPlusStudio;