import React, { useContext } from "react";
import parse from "html-react-parser";
import { motion } from 'framer-motion';
import intl from 'react-intl-universal';
import { Helmet } from 'react-helmet-async';

import { Context } from "../Context";
import { APP_NAME } from "../constants";
import { getSpecialMessageHTML } from "../utils";
import { PromptBuilderSamplesView } from './SamplesGallery/PromptBuilderSamplesView';

const PromptBuilderSamplesGallery = (props) => {
  const { authenticated, user, dynamicText } = useContext(Context);
  const specialMessage = getSpecialMessageHTML(authenticated, user, dynamicText);

  return (
    <motion.div className="content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Helmet>
        <title>{APP_NAME} { intl.get('PromptBuilder_Samples_Gallery') }</title>
        <meta name="description" content={ intl.get('PromptBuilder_Samples_Gallery_description') } />
      </Helmet>
      <div className="title">{ intl.get('PromptBuilder_Samples_Gallery') }</div>
      {specialMessage && (
        <div className="alert alert-danger mt-2">
          {parse(specialMessage)}
        </div>
      )}

      <div className="row m-2">
        <div className="col-12 introduction">
          { intl.get('PromptBuilder_Samples_Gallery_description') }
        </div>
      </div>

      <PromptBuilderSamplesView enableLightbox={true} />
    </motion.div>
    );
};

export default PromptBuilderSamplesGallery;