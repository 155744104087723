import React, { useContext, useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import parse from "html-react-parser";
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useLongPress } from "use-long-press";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import copy from 'copy-to-clipboard';
import intl from 'react-intl-universal';
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import { Helmet } from 'react-helmet-async';

import { Context } from "../Context";
import { APP_NAME, SERVER_API_BASE_URL, EMBEDDING_TYPES } from "../constants";
import UploadService from "../services/file-upload-prompt-builder.service";
import { resizeImage, getSpecialMessageHTML } from "../utils";

const PromptBuilder = (props) => {
  const { dynamicText, authenticated, sid, locale, user, stableDiffusionPrompts } = useContext(Context);
  const navigate = useNavigate();
  const [textPrompt, setTextPrompt] = useState('');
  let timer = null;

  const specialStylePrompts = useRef([]);
  const specialObjectPrompts = useRef([]);
  const genericStylePrompts = useRef([]);
  const artistPrompts = useRef([]);
  const otherPrompts = useRef([]);

  // Sort the prompts by token name
  const comparePrompts = (a, b) => {
    if (a.token > b.token) return 1; 
    if (a.token < b.token) return -1; 
    return 0;
  }

  useEffect(() => { 
    specialStylePrompts.current = stableDiffusionPrompts.filter(x => x.type === 'style').sort(comparePrompts);
    specialObjectPrompts.current = stableDiffusionPrompts.filter(x => x.type === 'object').sort(comparePrompts);
    genericStylePrompts.current = stableDiffusionPrompts.filter(x => ['medium', 'movement'].includes(x.type)).sort(comparePrompts);
    artistPrompts.current = stableDiffusionPrompts.filter(x => x.type === 'artist').sort(comparePrompts);
    otherPrompts.current = stableDiffusionPrompts.filter(x => !['style', 'object', 'medium', 'movement', 'artist'].includes(x.type)).sort(comparePrompts);
  }, [stableDiffusionPrompts]);


  // Image2Text related code
  const [inputImage, setInputImage] = useState(undefined);
  const [previewInputImage, setPreviewInputImage] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");

  const selectInputImage = async (event) => {
    let currentFile = event.target.files[0];
    if (currentFile.size > 2097152 || currentFile.size < 100) {  //no need to resize when size < 2M, and a too small file is probably invalid
      try {
        currentFile = await resizeImage(currentFile);
      } catch(err) {
        setInputImage(undefined);
        setPreviewInputImage(undefined);
        setProgress(0);
        setMessage(intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }));
        return;
      }
    }
  
    setInputImage(currentFile);
    setPreviewInputImage(URL.createObjectURL(currentFile));
    setProgress(0);
    setMessage("");
  }

  const uploadAll = (files, sid, locale) => {
    const duration = 60 * 1000;
    const startTime = Date.now();
    timer = null;
    setProgress(0);
    setMessage("");

    UploadService.getServerStatus().then((serverStatus) => {
      if (serverStatus < 0) {
        setProgress(0);
        setMessage(intl.getHTML('ERROR_MESSAGE_SERVER_UNAVAILABLE'));
      }
      else if (serverStatus < 1) {
        setProgress(0);
        setMessage(intl.getHTML('ERROR_MESSAGE_SERVER_BUSY'));
      }
      else UploadService.upload(files, sid, locale, (event) => {
        if (timer === null)
          timer = setInterval(() => {
            const percentage = Math.round(((Date.now() - startTime) / duration) * 100);
            if (percentage > 96) 
              clearInterval(timer);
            else 
              setProgress(percentage);
          }, 500);
      }).then((response) => {
        clearInterval(timer);
        setProgress(100);
        const msg = response.data.message;
        setMessage(intl.get('Generated_text') + msg);
        setTextPrompt(textPrompt ? textPrompt + '; ' + msg : msg);
      })
      .catch((reason) => {
        clearInterval(timer);
        if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
          setProgress(0);
          setMessage(intl.getHTML('ERROR_MESSAGE_KNOWN_REASON', { reason: reason.response.data.message }));
        } else {
          setProgress(0);
          setMessage(intl.getHTML('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }));
        }
      })
    });
  }

  const uploadImages = (sid, locale) => {
    if (inputImage === undefined) {
      setProgress(0);
      setMessage(intl.getHTML('ERROR_MESSAGE_NO_FILE_SELECTED'));
      return;
    }
    const selectedFiles = [];
    selectedFiles.push(inputImage);
    uploadAll(selectedFiles, sid, locale);
  }


  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState('Image2Text');
  const [itemDisplayMode, setItemDisplayMode] = useState([]);
  
  // Toggle active state for Tab
  const toggleTab = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    setItemDisplayMode([]);
  }

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const bind = useLongPress((e) => {
    const selectedText = e.target.innerText;
    addText(selectedText);
  });
  
  const addText = (selectedText) => {
    setTextPrompt(textPrompt ? textPrompt + ' ' + selectedText : selectedText);
    //alert('Added ' + selectedText + ' to the text area');
    //setItemDisplayMode([]);
    document.getElementById('createRandomPromptFromTemplate').scrollIntoView();   //should set focus on textPrompt, but the focused position is a bit low in Firefox
    forceUpdate();
  };
  
  const [embeddingType, setEmbeddingType] = useState('style');

  const ShowNavItem = ({ tabId, title }) => {
    return (
      <NavItem>
        <NavLink className={classnames("flex-md-fill text-md-center", {active: currentActiveTab === tabId})} onClick={() => { toggleTab(tabId); }}>
          <h4>{title}</h4>  
        </NavLink>
      </NavItem>        
    )
  }

  // prompts related code
  /*
  const ShowItem0 = ({ item, index }) => {
    return (
      <li className="list-group-item" key={index}>
        <div className="termDIV" tabIndex="0" {...bind(item.token)}>
          {item.score < 1 && (
            textPrompt.indexOf(item.token) === -1 ? item.token : <font color="Green"><em>{item.token} *</em></font>
          )}
          {item.score >= 1 && item.score < 10 && (
            <h5>{textPrompt.indexOf(item.token) === -1 ? item.token : <font color="Green"><em>{item.token} *</em></font>}</h5>
          )}
          {item.score >= 10 && (
            <h4>{textPrompt.indexOf(item.token) === -1 ? item.token : <font color="Green"><em>{item.token} *</em></font>}</h4>
          )}
        </div>
        <div className="hide" tabIndex="1">
          {['medium', 'movement'].includes(item.type) && (
          <img className="preview" src={new URL(SERVER_API_BASE_URL).origin + '/assets/' + item.token.replaceAll(' ', '_') + '.jpg'} alt="a sample" />
          )}
          {!['medium', 'movement'].includes(item.type) && item.sampleImageLink && (
            <img className="preview" src={item.sampleImageLink} alt="a sample" />
          )}
        </div>
      </li>
    )
  }
*/
const onChangeOfEmbeddingType = e => {
  setEmbeddingType(e.target.value);
  setItemDisplayMode([]);
}

useEffect(() => {
  const handleResize = () => {
    setItemDisplayMode([]);
  }
  window.addEventListener('resize', handleResize);
}, [])

useEffect(() => { 
  if (itemDisplayMode && itemDisplayMode.every(a => a['value'] === 0))
    itemDisplayMode.forEach(x => {
      x['offset'] = window.scrollY + document.getElementById(x['id']).getBoundingClientRect().top - 80;
    });
}, [itemDisplayMode])

const onChangeOfItemDisplayMode = (itemContainer, isPlus) => { 
    if (isPlus) {
      itemContainer['value']++; 
      if (itemContainer['value'] > 1) 
        itemContainer['value'] = 1; 
    } else {
      itemContainer['value']--; 
      if (itemContainer['value'] < -1) 
        itemContainer['value'] = -1; 
    }
    itemDisplayMode.forEach(x => {
      if (x['id'] !== itemContainer['id'] && x['value'] > 0) 
        x['value'] = 0;
    });
    //const item = document.getElementById(itemContainer['id']);
    //item.focus();   
    //item.scrollIntoView();
    window.scrollTo(0, itemContainer['offset']);
    forceUpdate(); 
  }

  const fileBaseName = new URL(SERVER_API_BASE_URL).origin + '/assets/promptBuilderSamples/' + APP_NAME + '-';
  const ShowItem = ({ item, index, mode }) => {
    return (
      <li className="list-group-item" key={index}>
        <div className="btn termDIV text-wrap" tabIndex="0" {...bind(item.token)}>
          {textPrompt.indexOf(item.token) === -1 ? (
            <h6 className="preview text-wrap">{item.token}</h6>  
          ) : (
            <h6 className="preview text-wrap"><font color="Green"><em>{item.token} *</em></font></h6> 
          )}
        </div>
        <div className={mode ? "" : "hide"} tabIndex="1">
          {/*['medium', 'movement'].includes(item.type) && (
            <LazyLoadImage className="preview" src={new URL(SERVER_API_BASE_URL).origin + '/assets/' + item.token.replaceAll(' ', '_') + '.jpg'} alt={"a sample of " + item.token} />
          )}
          {['style', 'object'].includes(item.type) && (
            <LazyLoadImage className="preview" src={new URL(SERVER_API_BASE_URL).origin + '/assets/special/' + item.token.replaceAll('<', '[').replaceAll('>', ']').replaceAll(' ', '_') + '.jpg'} alt={"a sample of " + item.token} />
          )}
          {!['medium', 'movement', 'style', 'object'].includes(item.type) && item.sampleImageLink && (
            <LazyLoadImage className="preview" src={item.sampleImageLink} alt={"a sample of " + item.token} />
          )}
          {!['medium', 'movement', 'style', 'object'].includes(item.type) && !item.sampleImageLink && item.score > 0 && (
            <LazyLoadImage className="preview" src={new URL(SERVER_API_BASE_URL).origin + '/assets/' + item.token.replaceAll(' ', '_') + '.jpg'} alt={"a sample of " + item.token} />
          )*/}
          {['style', 'object'].includes(item.type) ? (
            <LazyLoadImage className="preview" src={fileBaseName + item.token.replaceAll('<', '[').replaceAll('>', ']').replaceAll(' ', '_') + '_0.jpg'} alt={"a sample of " + item.token} />
          ) : (
            <LazyLoadImage className="preview" src={fileBaseName + item.token.replaceAll(' ', '_') + '_0.jpg'} alt={"a sample of " + item.token} />
          )}
          {item.note && (
            <h6 className="preview text-wrap">{item.note}</h6>
          )}
        </div>
      </li>
    )
  }

  const ShowItemsStartWith = ({ promptList, str, prefix }) => {
    const myId = prefix + str;
    let itemContainer = itemDisplayMode.filter(a => a['id'] === myId)[0];
    if (typeof itemContainer === 'undefined') {
      itemContainer = {id: myId, value: 0};
      itemDisplayMode.push(itemContainer);
    }
    return (
      <div id={myId} key={myId} tabIndex="0">
        <h5 className="card-title">
          &nbsp;&nbsp;{intl.get('Group')} - {str}&nbsp;&nbsp;
          {itemContainer['value'] <= 0 ? <MdAddCircle title={intl.get('expand_all')} onClick={() => onChangeOfItemDisplayMode(itemContainer, true)} /> : ""}&nbsp;&nbsp;
          {itemContainer['value'] >= 0 ? <MdRemoveCircle title={intl.get('collapse_all')} onClick={() => onChangeOfItemDisplayMode(itemContainer, false)} /> : ""}
        </h5>
        {itemContainer['value'] >= 0 && str === '#' && (
          <ul className="card-deck">
            {promptList.current.filter(x => !isNaN(x.token[0]) || (x.token[0] === '<' && !isNaN(x.token[1]))).map((item, index) => (
              <ShowItem item={item} index={index} mode={itemContainer['value']} />
            ))}
          </ul>
        )}
        {itemContainer['value'] >= 0 && str !== '#' && (
          <ul className="card-deck">
            {promptList.current.filter(x => x.token[0].toUpperCase() === str || (x.token[0] === '<' && x.token[1].toUpperCase() === str)).map((item, index) => (
              <ShowItem item={item} index={index} mode={itemContainer['value']} />
            ))}
          </ul>
        )}
      </div>
    )
  }

  const ShowItemsOfType = ({ promptList, type }) => {
    let itemContainer = itemDisplayMode.filter(a => a['id'] === type)[0];
    if (typeof itemContainer === 'undefined') {
      itemContainer = {id: type, value: 0, offset: window.scrollY};
      itemDisplayMode.push(itemContainer);
    }
    return (
      <div id={type} key={type} tabIndex="0">
        <h5 className="card-title">
          &nbsp;&nbsp;{intl.get('Group')} - {intl.get(type)}&nbsp;&nbsp;
          {itemContainer['value'] <= 0 ? <MdAddCircle title={intl.get('expand_all')} onClick={() => onChangeOfItemDisplayMode(itemContainer, true)} /> : ""}&nbsp;&nbsp;
          {itemContainer['value'] >= 0 ? <MdRemoveCircle title={intl.get('collapse_all')} onClick={() => onChangeOfItemDisplayMode(itemContainer, false)} /> : ""}
        </h5>
        {itemContainer['value'] >= 0 && (
          <ul className="card-deck">
            {promptList.current.filter(x => x.type === type).map((item, index) => (
              <ShowItem item={item} index={index} mode={itemContainer['value']} />
            ))}
          </ul>
        )}
      </div>
    )
  }

  // Create Random Prompt function
  const getRandomItem = (list) => {
    return list[Math.floor((Math.random()*list.length))];
  }

  const createRandomPromptFromTemplate = () => {
    //template: subject, scene, style, artist, lighting, detail, perspective;
    let randomPrompt = getRandomItem(specialObjectPrompts.current.concat(otherPrompts.current.filter(x => x.type === 'figure'))).token + ', ';
    randomPrompt += getRandomItem(otherPrompts.current.filter(x => ['scene', 'festival'].includes(x.type))).token + ', ';
    randomPrompt += getRandomItem(specialStylePrompts.current.concat(genericStylePrompts.current)).token + ', ';
    randomPrompt += 'by ' + getRandomItem(artistPrompts.current).token + ', ';
    randomPrompt += getRandomItem(otherPrompts.current.filter(x => x.type === 'lighting')).token + ', ';
    randomPrompt += getRandomItem(otherPrompts.current.filter(x => x.type === 'detail')).token + ', ';
    randomPrompt += getRandomItem(otherPrompts.current.filter(x => x.type === 'perspective')).token;
    setTextPrompt(randomPrompt);
    document.getElementById('textPrompt').focus();   
  };

  
  const initialPromptFromDatabaseButtonText = intl.get('Find_Prompt_from_Database');
  const initialCopyButtonText = intl.get('Copy_To_Clipboard');
  const [promptFromDatabaseButtonText, setPromptFromDatabaseButtonText] = useState(""); 
  const [copyButtonText, setCopyButtonText] = useState(""); 
  useEffect(() => { 
    setPromptFromDatabaseButtonText(initialPromptFromDatabaseButtonText);
    setCopyButtonText(initialCopyButtonText);
  }, [initialPromptFromDatabaseButtonText, initialCopyButtonText])


  // the effect of changing the button text back after a timeout
  useEffect(() => { 
    if (promptFromDatabaseButtonText.indexOf('</font>') !== -1) {
      setTimeout(() => setPromptFromDatabaseButtonText(initialPromptFromDatabaseButtonText), [1000])
    }
  }, [initialPromptFromDatabaseButtonText, promptFromDatabaseButtonText])

  const findRandomPromptFromDatabase = async (e) => {
    setPromptFromDatabaseButtonText(intl.get('finding'));
    const response = await fetch(SERVER_API_BASE_URL + "/findRandomPrompt", {
      method: "POST",
      headers: {
        'Accept': 'application/json, text/plain, */*',
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify({textPrompt: textPrompt, locale: locale}),
    });
    const result = await response.json();
    //const result = {'text': 'sample prompt', 'status': '6 matching records found'};
    if (result.text)
      setTextPrompt(result.text);
    setPromptFromDatabaseButtonText(`<font color="Black">${result.status}</font>`);
    document.getElementById('textPrompt').focus();   
  };
  

  // the effect of changing the button text back after a timeout
  useEffect(() => { 
    if (copyButtonText !== initialCopyButtonText) {
      setTimeout(() => setCopyButtonText(initialCopyButtonText), [500])
    }
  }, [initialCopyButtonText, copyButtonText])

  const copyToCipboard = (textPrompt) => {
    //navigator.clipboard.writeText(textPrompt);  - not working on mobile
    copy(textPrompt);
    setCopyButtonText(intl.getHTML('text_copied'));
  }

  const formGroupClassName = props.isLargeScreen ? "form-group row m-4" : "form-group row m-2";
  const specialMessage = getSpecialMessageHTML(authenticated, user, dynamicText);
  const startingCharGroups = ['#', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  const special1Groups = startingCharGroups.filter(c => !['Q', 'U'].includes(c));
  const special2Groups = startingCharGroups;
  const genericGroups = startingCharGroups.filter(c => !['X', 'Y', 'Z'].includes(c));
  const otherGroups = ['lighting', 'detail', 'perspective', 'artist', 'scene', 'figure', 'festival', 'misc'];

  return (
    <motion.div className="content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Helmet>
        <title>{APP_NAME} { intl.get('Prompt_Builder') }</title>
        <meta name="description" content={ intl.get('PromptBuilder_meta_description') } />
      </Helmet>
      <div className="title">{ intl.get('Prompt_Builder') }</div>
      {specialMessage && (
        <div className="alert alert-danger mt-2">
          {parse(specialMessage)}
        </div>
      )}

      {(!authenticated || user.displayMode === '1') && (
        <div className="row m-1">
          <div className="col-12 text-center">
            <div className="introduction">
              { intl.getHTML('Prompt_Builder_description') }
            </div>
          </div>
        </div>
      )}

    {(!authenticated || user.displayMode === '1') && props.isLargeScreen && (
        <div className="row mx-1 my-4">
          <div className="col-5 align-self-center introduction">
              { intl.getHTML('Prompt_Builder_instruction') }
          </div>
          <div className="col-7 text-center embed-responsive embed-responsive-16by9"> 
            <iframe width="560" height="315" src={dynamicText.promptBuilderVideo} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
          </div>
        </div>
      )}
      {(!authenticated || user.displayMode === '1') && !props.isLargeScreen && (
        <div className="row m-1">
          <div className="col-12 text-center">
            <div className="introduction">
              { intl.getHTML('Prompt_Builder_instruction') }
            </div>
            <div className="embed-responsive embed-responsive-16by9"> 
              <iframe width="560" height="315" src={dynamicText.promptBuilderVideo} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
            </div>
          </div>
        </div>
      )}

      <div className="row mx-4 alert alert-info">
        <div className="col-md-6 text-center">
          <button id="createRandomPromptFromTemplate" className="btn mb-2 mr-2 btn-lg highlight1"
            onClick={() => createRandomPromptFromTemplate()} >
            { intl.get('Create_Random_Prompt_from_Template') }
          </button>
        </div>
        <div className="col-md-6 text-center">
          <button className="btn mb-2 mr-2 btn-lg highlight1" 
            disabled={promptFromDatabaseButtonText !== initialPromptFromDatabaseButtonText}
            onClick={() => findRandomPromptFromDatabase()} >
            {parse(promptFromDatabaseButtonText)}
          </button>
        </div>
      </div>

      <div className={formGroupClassName}>
        <div className="col-12">
          <strong>{ intl.get('Text_Prompt') }</strong>
          <textarea className="form-control" type="text" id="textPrompt" style={{ border: '2px solid GreenYellow', fontWeight: 'bolder' }}
            placeholder={ intl.get('Text_Prompt_hint') }
            value={textPrompt} onChange={(e) => setTextPrompt(e.target.value)} >
          </textarea>
        </div>
      </div>
      <div className={formGroupClassName}>
        <div className="col-sm-4 text-center">
          <button className="btn mb-2 mr-2 btn-info" 
            disabled={!textPrompt || copyButtonText !== initialCopyButtonText}
            onClick={() => setTextPrompt('')} >
            { intl.get('Clear') }
          </button>
        </div>
        <div className="col-sm-4 text-center">
          <button className="btn mb-2 mr-2 btn-info" 
            disabled={!textPrompt || copyButtonText !== initialCopyButtonText}
            onClick={() => copyToCipboard(textPrompt)} >
            {copyButtonText}
          </button>
        </div>
        <div className="col-sm-4 text-center">
          <button className="btn mb-2 mr-2 btn-info" 
            onClick={() => navigate('/SuperStylizer')} >
            { intl.get('Go_To_Super_Stylizer') }
          </button>
        </div>
      </div>
        
      <div className={formGroupClassName} />
      <div>
        {/* status === 'Loading' && <div>Loading...</div>}
        {status === 'Error' && <div>Error finding the collection</div> */}
        <Nav pills fill className={"flex-column flex-xl-row"} style={{backgroundColor: "lightblue"}}>
          <ShowNavItem tabId={'Image2Text'} title={ intl.get('Image_To_Text') } />
          <ShowNavItem tabId={'TextualInversionTerms'} title={ intl.get('Textual_Inversion_Terms') } />
          <ShowNavItem tabId={'GenericStyles'} title={ intl.get('Generic_Styles') } />
          <ShowNavItem tabId={'OtherTerms'} title={ intl.get('Other_Useful_Terms') } />
        </Nav>
        
        <TabContent activeTab={currentActiveTab}>
          <TabPane tabId="Image2Text">
            <div className="card">
              <div className="row m-2">
                <div className="col-12">
                  { intl.get('Image2Text_description') }
                </div>
              </div>

              {!authenticated && (
                <div className="row highlight m-3">
                  <div className="col-12">
                  {/* intl.getHTML('Image2Text_usage_quota_message') */} { intl.get('MESSAGE_TOOL_NOT_FUNCTIONAL') }
                  </div>
                </div>
              )}

              <div className="row m-2">
                <div className="col-12">
                  <label className="btn mb-3 mr-3 btn-light">
                    <input type="file" accept="image/*" onChange={selectInputImage} />
                  </label>
                </div>
              </div>

              {previewInputImage && (
                <div className="row m-2">
                  <div className="col-12">
                    <img className="preview" src={previewInputImage} alt="" />
                  </div>
                </div>
              )}
              
              <div className="row m-2">
                <div className="col-12 text-center">
                  <button
                    className="btn mb-3 mr-3 btn-info btn-lg"
                    disabled={!(authenticated && inputImage && !progress)}
                    onClick={() => uploadImages(sid, locale)}
                    >
                    { intl.get('Generate_Text') }
                  </button>
                </div>
              </div>

              {authenticated && inputImage && (
                <div className="progress m-2">
                  <div
                    className="progress-bar progress-bar-info progress-bar-striped"
                    role="progressbar"
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: progress + "%" }}
                  >
                    {progress}%
                  </div>
                </div>
              )}

              {message && (
                <div className="alert alert-warning m-3 text-wrap" role="alert">
                  {message}
                </div> 
              )}
            </div>
          </TabPane>

          <TabPane tabId="TextualInversionTerms">
            <div className="card">
              <div className="row m-2">
                <div className="col-12 text-center alert alert-info">
                  <select className="selectpicker" data-style="btn-success" onChange={onChangeOfEmbeddingType}>
                    {EMBEDDING_TYPES.map(item => {
                        return (<option key={item.value} value={item.value}>{ intl.get('EMBEDDING_TYPES_' + item.value) }</option>);
                    })}
                  </select>
                </div>
              </div>

              {embeddingType === 'style' && (
                <div>
                  <ul className="nav">
                    {special1Groups.map(item => {
                      return (<li className="nav-item"><a className="nav-link" href={'#special1' + item}>{item}</a></li>);
                    })}
                  </ul>
                  {special1Groups.map(item => {
                    return (<ShowItemsStartWith promptList={specialStylePrompts}prefix='special1' str={item} />);
                  })}
                </div>
              )}

              {embeddingType === 'object' && (
                <div>
                  <ul className="nav">
                    {special2Groups.map(item => {
                      return (<li className="nav-item"><a className="nav-link" href={'#special2' + item}>{item}</a></li>);
                    })}
                  </ul>
                  {special2Groups.map(item => {
                    return (<ShowItemsStartWith promptList={specialObjectPrompts}prefix='special2' str={item} />);
                  })}
                </div>
              )}
            </div>
          </TabPane>

          <TabPane tabId="GenericStyles">
            <div className="card">
              <ul className="nav">
                {genericGroups.map(item => {
                  return (<li className="nav-item"><a className="nav-link" href={'#generic' + item}>{item}</a></li>);
                })}
              </ul>
              {genericGroups.map(item => {
                return (<ShowItemsStartWith promptList={genericStylePrompts}prefix='generic' str={item} />);
              })}
            </div>
          </TabPane>

          <TabPane tabId="OtherTerms">
            <div className="card">
              <ul className="nav">
                {otherGroups.map(item => {
                  return (<li className="nav-item"><a className="nav-link" href={'#' + item}>{item}</a></li>);
                })}
              </ul>
              {otherGroups.map(item => {
                return (item === 'artist'? <ShowItemsOfType promptList={artistPrompts} type={item} /> : <ShowItemsOfType promptList={otherPrompts} type={item} />);
              })}
            </div>
          </TabPane>
        </TabContent>
      </div>

    </motion.div>
    );
};

export default PromptBuilder;