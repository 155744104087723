import { cpuServer } from "../http-common";

class FileUploadService4Enhancer {
  upload(files, type, resultSizeType, sid, locale, onUploadProgress) {
    let formData = new FormData();
    files.forEach(f => {
      formData.append("file", f);
    });
    formData.append("type", type);
    formData.append("resultSizeType", resultSizeType);
    formData.append("sid", sid);
    formData.append("locale", locale);

    return cpuServer.post("/upload4Enhancer", formData, {  
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles(path) {
    if (path)
      return cpuServer.get(`/files/${path}`);
  }
}

export default new FileUploadService4Enhancer();
