import { cpuServer, gpuServer, gpuServer2 } from "../http-common";
import { SERVER_API_BASE_URL2, useNewServer4SpeechSynthesizer } from "../constants";

const server = useNewServer4SpeechSynthesizer ? gpuServer2 : gpuServer;
class FileUploadService4StorybookMaker {
  async getServerStatus() {
    //const isServer2 = gpuServer.defaults.baseURL.includes('remoteapi');
    let serverName = 'development';
    if (useNewServer4SpeechSynthesizer) {
      serverName = 'server3';
    } else {
      if (SERVER_API_BASE_URL2.includes('remoteapi'))
        serverName = 'server2';
    }
    const res = await cpuServer.get("/checkGpuServerStatus?server=" + serverName);
    if (res.data.isServerAvailable < "1")
      return -1;  //server unavailable
    if ((serverName !== 'development' && res.data.numRunningJobs > 0) || (serverName === 'development' && res.data.numRunningJobs > 4))
      return 0;  //server busy
    return 1;  //server normal
  }

  upload(files, generationMethod, prompt, url, documentIsPdf, story, genre, pictureStyle, pictureResolution, selectedVoice, useCustomVoice, pitch, speed, volume, usePresenter, shape, location, height, sid, locale, onUploadProgress) {
    let formData = new FormData();
    files.forEach(f => {
      formData.append("file", f);
    });
    formData.append("generationMethod", generationMethod);
    formData.append("prompt", prompt);
    formData.append("url", url);
    formData.append("documentIsPdf", documentIsPdf);
    formData.append("story", story);
    formData.append("genre", genre);
    formData.append("pictureStyle", pictureStyle);
    formData.append("pictureResolution", pictureResolution);
    formData.append("selectedVoice", selectedVoice);
    formData.append("useCustomVoice", useCustomVoice);
    formData.append("pitch", pitch);
    formData.append("speed", speed);
    formData.append("volume", volume);
    formData.append("usePresenter", usePresenter);
    formData.append("shape", shape);
    formData.append("location", location);
    formData.append("height", height);
    formData.append("sid", sid);
    formData.append("locale", locale);

    return server.post("/upload4StorybookMaker", formData, {  
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
      timeout: 10 * 60 * 1000,
    });
  }

  getFiles(path) {
    return server.get(`/files/${path}`);
  }
}

export default new FileUploadService4StorybookMaker();