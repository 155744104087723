import React, { useContext, useRef, useEffect } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import intl from 'react-intl-universal';

import { Context } from "../../Context";
import { SERVER_API_BASE_URL } from "../../constants";

const PayPalButton = ({subscriptionPlanId}) => {
  const { authenticated, user, sid, locale } = useContext(Context);
  //console.log(subscriptionPlanId);
  //console.log(sid);

  const mySid = useRef("");
  const myLocale = useRef("");
  const myUserId = useRef("");
  const mySubscriptionId = useRef("");
  useEffect(() => { 
    mySid.current = sid;
    myLocale.current = locale;
    myUserId.current = user.provider + ' ' + user.id;
    mySubscriptionId.current = user.subscriptionId;
  }, [sid, locale, user]);

  const createSubscription = (data, actions) => {
    //console.log(mySubscriptionId.current);
    if (mySubscriptionId.current) {
      // change subscription, the new price is effective starting on the next billing cycle. https://developer.paypal.com/docs/subscriptions/customize/revise-subscriptions/ 
      return actions.subscription.revise(mySubscriptionId.current, {
        plan_id: subscriptionPlanId,
        custom_id: myUserId.current,
      })
    } else {  // new subscription
      return actions.subscription.create({
        plan_id: subscriptionPlanId,
        custom_id: myUserId.current,
      })
    }
  }

  const onClick = (data, actions) => {
    //console.log(mySid.current);
    /*
    if (!authenticated || !mySid.current) {
      alert(`Please login then subscribe to the plan`);
      return actions.reject();
    }
    if (subscriptionPlanId === user.subscriptionPlanId) {
      alert(`You already subscribed to the plan`);
      return actions.reject();
    }
    */
    return actions.resolve();
  }

  const onApprove = (data, actions) => {
    const paymentDetails = {
      sid: mySid.current,
      locale: myLocale.current,
      subscriptionId: data.subscriptionID,
      subscriptionPlanId: subscriptionPlanId,
      //orderId: data.orderId,
    };
    //console.log(paymentDetails);
    fetch(
      SERVER_API_BASE_URL + "/updateAccountOnPaymentApproval", {
        method: "POST",
        headers: {
          'Accept': 'application/json, text/plain, */*',
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(paymentDetails),
    })
    .then(async response => {
      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.err);
      } 
      // result should contain account update successful message
      //alert('You have successfully created subscription ' + data.subscriptionID);
      window.location.reload();
    })
    .catch((err) => {
      // Handle any error
      alert(`An error occured while updating your account: ${err}`);
    });
  }

  const onError = (data, actions) => {
    alert(`An error occured with your payment: ${data}`);
  }

  return (
    <React.Fragment>
      {!authenticated && (
        <div className="container-fluid text-center">
            <button className="btn1 mb-3 mr-3 btn-info" onClick={() => alert(intl.get('login_and_subscribe'))}> { intl.get('Select_Plan') } </button>
        </div>
      )}
      {authenticated && subscriptionPlanId === user.subscriptionPlanId && (
        <div className="alert alert-dark container-fluid text-center">
          { intl.getHTML('current_plan') } 
        </div>
      )}
      {authenticated && subscriptionPlanId !== user.subscriptionPlanId && subscriptionPlanId === user.nextSubscriptionPlanId && (
        <div className="alert alert-dark container-fluid text-center">
          { intl.getHTML('next_plan') } 
        </div>
      )}
      {authenticated && !user.subscriptionPlanId && (
        <div id={subscriptionPlanId}>
          <PayPalButtons 
            style={{ label: "subscribe", layout: "vertical" }} 
            createSubscription={createSubscription}
            onClick={onClick}
            onApprove={onApprove}
            onError={onError}
          />
        </div>
      )}
    </React.Fragment>
  );

    //the last condition if subscriptionPlan can be modified
    //authenticated && subscriptionPlanId !== user.subscriptionPlanId && subscriptionPlanId !== user.nextSubscriptionPlanId && (!user.subscriptionPlanId || user.nextSubscriptionPlanId) && (
}

export default PayPalButton;