import { cpuServer } from "../http-common";

class FileUploadService4BackgroundEditor {
  upload(files, action, location, foregroundTransparency, backgroundTransparency, boundaryPixelsToBlend, gifMode, resultSizeType, sid, locale, onUploadProgress) {
    let formData = new FormData();
    files.forEach(f => {
      formData.append("file", f);
    });
    formData.append("action", action);
    formData.append("location", location);
    formData.append("foregroundTransparency", foregroundTransparency);
    formData.append("backgroundTransparency", backgroundTransparency);
    formData.append("boundaryPixelsToBlend", boundaryPixelsToBlend);
    formData.append("gifMode", gifMode);
    formData.append("resultSizeType", resultSizeType);
    formData.append("sid", sid);
    formData.append("locale", locale);

    return cpuServer.post("/upload4BackgroundEditor", formData, {  
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles(path) {
    if (path)
      return cpuServer.get(`/files/${path}`);
  }
}

export default new FileUploadService4BackgroundEditor();
