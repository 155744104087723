import React, { useState, useContext, useEffect } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import copy from 'copy-to-clipboard';
import intl from 'react-intl-universal';

import { Context } from "../../Context";
import { APP_NAME, SERVER_API_BASE_URL } from "../../constants";

const fileBaseName = new URL(SERVER_API_BASE_URL).origin + '/assets/promptBuilderSamples/' + APP_NAME + '-';
const groups = [
  {title: 'EMBEDDING_TYPES_style', type: 'style'}, 
  {title: 'EMBEDDING_TYPES_object', type: 'object'}, 
  {title: 'art_medium', type: 'medium'}, 
  {title: 'art_movement', type: 'movement'}, 
  {title: 'lighting', type: 'lighting'}, 
  {title: 'detail', type: 'detail'}, 
  {title: 'perspective', type: 'perspective'}, 
  {title: 'artist', type: 'artist'}, 
  {title: 'scene', type: 'scene'}, 
  {title: 'figure', type: 'figure'}, 
  {title: 'festival', type: 'festival'}, 
  {title: 'misc', type: 'misc'}
];

export const PromptBuilderSamplesView = ({enableLightbox}) => {
  //console.log(enableLightbox);
  const { stableDiffusionPrompts } = useContext(Context);
  const [inputImages, setInputImages] = useState([]);
  const [outputImages, setOutputImages] = useState([]);
  const [outputImageIndex, setOutputImageIndex] = useState(-1);
  const [clipboardContent, setClipboardContent] = useState('');

  // Sort the prompts by token name
  const comparePrompts = (a, b) => {
    if (a.token > b.token) return 1; 
    if (a.token < b.token) return -1; 
    return 0;
  }

  useEffect(() => { 
    if (stableDiffusionPrompts.length > 0) {
      const images = [];
      groups.forEach((item, key) => {
        const image = {
          thumbnailCaption: intl.get(item.title),
          width: 128,
          height: 128,
        };
        const firstItem = stableDiffusionPrompts.filter(x => x.type === item.type).sort(comparePrompts)[0];
        switch (item.type) {
          case 'style':
          case 'object':
            image.src = fileBaseName + firstItem.token.replaceAll('<', '[').replaceAll('>', ']').replaceAll(' ', '_') + '.jpg';
            break;
          default:
            image.src = fileBaseName + firstItem.token.replaceAll(' ', '_') + '.jpg';
        }
        images.push(image);
      });
      setInputImages(images);
    }
  }, [stableDiffusionPrompts]);


  const onClickOfInputImage = (index) => {
    const nextImages = inputImages.map((image, i) =>
      i === index ? { ...image, isSelected: true } : { ...image, isSelected: false }
    );
    setInputImages(nextImages);
    const images = [];
    stableDiffusionPrompts.filter(x => x.type === groups[index].type).sort(comparePrompts).forEach((item, key) => {
      const image = {
        thumbnailCaption: item.token,
        caption: item.note,
        width: 128,
        height: 128,
      };
      switch (item.type) {
        case 'style':
        case 'object':
          image.src = fileBaseName + item.token.replaceAll('<', '[').replaceAll('>', ']').replaceAll(' ', '_') + '.jpg';
          image.original = fileBaseName + item.token.replaceAll('<', '[').replaceAll('>', ']').replaceAll(' ', '_') + '_0.jpg';
          break;
        default:
          image.src = fileBaseName + item.token.replaceAll(' ', '_') + '.jpg';
          image.original = fileBaseName + item.token.replaceAll(' ', '_') + '_0.jpg';
      }
      images.push(image);
    });
    setOutputImages(images);
  }

  if (inputImages.length > 0 && outputImages.length === 0)
    onClickOfInputImage(0);

  const onClickOfOutputImage = (index) => {
    setOutputImageIndex(index);
  }

  const onSelectOutputImage = (index) => {
    const nextImages = outputImages.map((image, i) =>
      i === index ? { ...image, isSelected: !image.isSelected } : image
    );
    setOutputImages(nextImages);
    let prompt = clipboardContent;
    nextImages.forEach((item, key) => {
      if (item.isSelected && prompt.indexOf(item.thumbnailCaption) === -1)
        prompt += prompt.length > 0 ? ', ' + item.thumbnailCaption : item.thumbnailCaption;
    });
    setClipboardContent(prompt);
    copy(prompt);
  }

  return (
    <div>
      <div className="row m-1 introduction highlight1">
        { intl.get('Prompt_Catagory') }
      </div>
      {inputImages.length > 0 && (
        <Gallery id='input'
          images={inputImages}
          onClick={onClickOfInputImage}
          enableImageSelection={false}
        />
      )}
      <div className="row m-1 introduction highlight1">
        { enableLightbox ? intl.get('Prompt_Samples_with_original') : intl.get('Prompt_Samples') }
      </div>
      {outputImages.length > 0 && (
        <Gallery id='output'
          images={outputImages}
          onClick={onClickOfOutputImage}
          onSelect={onSelectOutputImage}
        />
      )}
      {enableLightbox && outputImages.length > 0 && outputImageIndex > -1 && (
        <Lightbox
          imageTitle={outputImages[outputImageIndex].thumbnailCaption}
          imageCaption={outputImages[outputImageIndex].caption}
          mainSrc={outputImages[outputImageIndex].original}
          nextSrc={outputImages[(outputImageIndex + 1) % outputImages.length].original}
          prevSrc={outputImages[(outputImageIndex + outputImages.length - 1) % outputImages.length].original}
          onCloseRequest={() => setOutputImageIndex(-1)}
          onMovePrevRequest={() => setOutputImageIndex((outputImageIndex + outputImages.length - 1) % outputImages.length)}
          onMoveNextRequest={() => setOutputImageIndex((outputImageIndex + 1) % outputImages.length)}
        />
    )}
    </div>
  );
}

