import React, { useState, useContext, useEffect } from "react";
import { motion } from 'framer-motion';
import parse from "html-react-parser";
import intl from 'react-intl-universal';
import { Helmet } from 'react-helmet-async';

import { Context } from "../Context";
import { APP_NAME, SERVER_API_BASE_URL } from "../constants";
import { resizeImage, getBase64String, getSpecialMessageHTML } from "../utils";

const Contact = (props) => {
  const { dynamicText, authenticated, user, locale } = useContext(Context);

  const [inputImage, setInputImage] = useState(undefined);
  const [previewInputImage, setPreviewInputImage] = useState(undefined);
  const [message, setMessage] = useState("");

  const selectInputImage = async (event) => {
    let currentFile = event.target.files[0];
    try {
      currentFile = await resizeImage(currentFile);
    } catch(err) {
      setInputImage(undefined);
      setPreviewInputImage(undefined);
      setMessage(intl.getHTML('ERROR_MESSAGE_FILE_INVALID_AND_NOT_TO_BE_INCLUDED', { filename: currentFile.name }));
      return;
    }
  
    setInputImage(currentFile);
    setPreviewInputImage(URL.createObjectURL(currentFile));
    setMessage("");
  }

  const initialSubmitButtonText = intl.get('Submit');
  const [submitButtonText, setSubmitButtonText] = useState("");
  useEffect(() => { 
    setSubmitButtonText(initialSubmitButtonText);
  }, [initialSubmitButtonText])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitButtonText(intl.get('sending'));
    const { name, email, message } = e.target.elements;
    const details = {
      name: name.value,
      email: email.value,
      message: message.value,
      locale: locale,
    };
    if (inputImage) {
      details.inputImage = await getBase64String(inputImage);
      details.filename = inputImage.name;
      details.encoding = 'base64';
    }
    if (authenticated) {
      details.message += '<p/>Sent by user: <br/>' + JSON.stringify(user);
    }
    const response = await fetch(SERVER_API_BASE_URL + "/feedback", {
      method: "POST",
      headers: {
        'Accept': 'application/json, text/plain, */*',
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    const result = await response.json();
    setMessage(result.status);
    setSubmitButtonText(initialSubmitButtonText);
  };
  
  const formRowClassName = props.isLargeScreen ? "row mx-5 px-5" : "row mx-1";
  const specialMessage = getSpecialMessageHTML(authenticated, user, dynamicText);

  return (
    <motion.div className="content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Helmet>
        <title>{APP_NAME} { intl.get('Contact') }</title>
        <meta name="description" content={ intl.get('Contact_meta_description') } />
      </Helmet>
      <div className="title">{ intl.get('Contact') }</div>
      {specialMessage && (
        <div className="alert alert-danger mt-2">
          {parse(specialMessage)}
        </div>
      )}
      
      <div className="row m-1">
        <div className="col-12 text-center">
          <div className="introduction">
            { intl.getHTML('Contact_description') }
          </div>
        </div>
      </div>
      <div className={formRowClassName} />
      <form className="form-horizontal card p-3 bg-light" onSubmit={handleSubmit}>
        <div className="form-group row m-2">
          <label className="control-label col-sm-3" htmlFor="name">{ intl.get('Name') } <font color="Red">*</font></label>
          <div className="col-sm-9">
            {authenticated ? (
            <input className="form-control" type="text" id="name" defaultValue={user.displayName} required />
            ) : (
            <input className="form-control" type="text" id="name" required />
            )}
          </div>
        </div>
        <div className="form-group row m-2">
          <label className="control-label col-sm-3" htmlFor="email">{ intl.get('Email') } <font color="Red">*</font></label>
          <div className="col-sm-9">
            {authenticated ? (
            <input className="form-control" type="email" id="email" defaultValue={user.email} required />
            ) : (
            <input className="form-control" type="email" id="email" required />
            )}
          </div>
        </div>
        <div className="form-group row m-2">
          <label className="control-label col-sm-3" htmlFor="message">{ intl.get('Message') } <font color="Red">*</font></label>
          <div className="col-sm-9">
            <textarea className="form-control" type="text" id="message" required />
          </div>
        </div>
        <div className="form-group row m-2">
          <label className="control-label col-sm-3" htmlFor="message">{ intl.get('Attach_Image') }</label>
          <div className="col-sm-9">
            <label className="btn mb-3 mr-3 btn-light">
              <input className="form-control" type="file" accept="image/*" onChange={selectInputImage} />
            </label>
            {previewInputImage && (
              <div>
                <img className="preview" src={previewInputImage} alt="" />
              </div>
            )}
          </div>
        </div>
        <div className="form-group row m-3">
          <div className="col-sm-12 text-center">
            <button className="btn mb-3 mr-3 btn-info btn-lg" type="submit" disabled={submitButtonText!==initialSubmitButtonText}>
              {submitButtonText}
            </button>
          </div>
        </div>

        {message && (
          <div className="alert alert-warning my-1 text-wrap" role="alert">
            {message}
          </div> 
        )}
      </form>
    </motion.div>
  );
};

export default Contact;