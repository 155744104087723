import React, { useState } from 'react';
import { MdShare } from "react-icons/md";
import { APP_NAME, SERVER_API_BASE_URL } from '../../constants';
import intl from 'react-intl-universal';
import './share.scss';

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  PinterestShareButton,
  PinterestIcon,
  TumblrShareButton,
  TumblrIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  //ViberShareButton,
  //ViberIcon,
  LineShareButton,
  LineIcon,
  PocketShareButton,
  PocketIcon,
  InstapaperShareButton,
  InstapaperIcon,
  VKShareButton,
  VKIcon,
  LivejournalShareButton,
  LivejournalIcon,
  OKShareButton,
  OKIcon,
  WeiboShareButton,
  WeiboIcon,
  HatenaShareButton,
  HatenaIcon,
  EmailShareButton,
  EmailIcon,
  MailruShareButton,
  MailruIcon,
} from 'react-share';

export const ShareButtons = ({title, url, tags}) => {
  return (
    <div>
      <FacebookShareButton url={url} quote={title} hashtag={[APP_NAME, ...tags].toString()}>
        <FacebookIcon size={40} round={true}/>
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title} hashtags={[APP_NAME, ...tags]}>
        <TwitterIcon size={40} round={true} />
      </TwitterShareButton>

      <LinkedinShareButton url={url}>
        <LinkedinIcon size={40} round={true}/>
      </LinkedinShareButton>

      <RedditShareButton url={url} title={title} >
        <RedditIcon size={40} round={true} />
      </RedditShareButton>

      <PinterestShareButton url={url} media={url} description={title}>
        <PinterestIcon size={40} round={true}/>
      </PinterestShareButton>
    
      <TumblrShareButton url={url} tags={[APP_NAME, ...tags]}>
        <TumblrIcon size={40} round={true}/>
      </TumblrShareButton>
    
      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon size={40} round={true}/>
      </WhatsappShareButton>

      <TelegramShareButton url={url} title={title}>
        <TelegramIcon size={40} round={true}/>
      </TelegramShareButton>

      {/* 
      <ViberShareButton url={url} title={title}>
        <ViberIcon size={40} round={true}/>
      </ViberShareButton> 
      */}

      <LineShareButton url={url} title={title}>
        <LineIcon size={40} round={true}/>
      </LineShareButton>

      <PocketShareButton url={url} title={title}>
        <PocketIcon size={40} round={true}/>
      </PocketShareButton>

      <InstapaperShareButton url={url} title={title}>
        <InstapaperIcon size={40} round={true}/>
      </InstapaperShareButton>

      <VKShareButton url={url} title={title}>
        <VKIcon size={40} round={true}/>
      </VKShareButton>

      <LivejournalShareButton url={url} title={title}>
        <LivejournalIcon size={40} round={true}/>
      </LivejournalShareButton>

      <OKShareButton url={url} title={title}>
        <OKIcon size={40} round={true}/>
      </OKShareButton>

      <WeiboShareButton url={url} title={title}>
        <WeiboIcon size={40} round={true}/>
      </WeiboShareButton>

      <HatenaShareButton url={url} title={title}>
        <HatenaIcon size={40} round={true}/>
      </HatenaShareButton>

      <EmailShareButton url={url} subject={title}>
        <EmailIcon size={40} round={true}/>
      </EmailShareButton>

      <MailruShareButton url={url} title={title}>
        <MailruIcon size={40} round={true}/>
      </MailruShareButton>

    </div>
  );
};

export const PopupShare = ({title, uri, tags}) => {
  const shareUrl = new URL(SERVER_API_BASE_URL).origin + uri;
  // create state `open` with default as false
  const [open, setOpen] = useState(false);
  return (
    <>
      {/* click of button toggles `open` value therefore visibility */}
      <MdShare size={32} type="button" title={intl.get('Share_this_tool')} data-toggle="tooltip" onClick={() => setOpen(!open)} />
      {/* If open is true show your <div /> */}
      {open && (
        <ShareButtons title={title} url={shareUrl} tags={tags}/>
      )}
    </>
  )
};

