import { motion } from 'framer-motion';
import intl from 'react-intl-universal';
import { Helmet } from 'react-helmet-async';

import { APP_NAME } from "../constants";

const EmbeddedBlogger = () => {

  return (
    <motion.div className="content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Helmet>
        <title>{APP_NAME} { intl.get('Blog') }</title>
        <meta name="description" content={ intl.get('Blog_meta_description') } />
      </Helmet>
      <div className="title">{ intl.get('Blog') }</div>
      <iframe title="blogger" src="https://aitransformer.blogspot.com" width="100%" height="3800" frameBorder="0">
        <p>Your browser does not support iFrames, please visit <a href="https://aitransformer.blogspot.com">the Blog</a>.</p>
      </iframe>
    </motion.div>
    );
};

export default EmbeddedBlogger;