import axios from "axios";
import { SERVER_API_BASE_URL, SERVER_API_BASE_URL2, SERVER_API_BASE_URL5 } from "./constants";

export const cpuServer = axios.create({
  baseURL: SERVER_API_BASE_URL,
  headers: {
    "Content-type": "application/json"
  },
  withCredentials: true
}); 

export const gpuServer = axios.create({
  baseURL: SERVER_API_BASE_URL2,
  headers: {
    "Content-type": "application/json"
  },
  withCredentials: true
}); 

export const gpuServer2 = axios.create({
  baseURL: SERVER_API_BASE_URL5,
  headers: {
    "Content-type": "application/json"
  },
  withCredentials: true
}); 
