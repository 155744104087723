import { cpuServer } from "../http-common";

class FileUploadService4VideoFilter {
  upload(files, filter, resultSizeType, sid, locale, onUploadProgress) {
    let formData = new FormData();
    files.forEach(f => {
      formData.append("file", f);
    });
    formData.append("filter", filter);
    formData.append("resultSizeType", resultSizeType);
    formData.append("sid", sid);
    formData.append("locale", locale);

    return cpuServer.post("/upload4VideoFilter", formData, {  
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
      timeout: 30 * 60 * 1000,
    });
  }

  getFiles(path) {
    return cpuServer.get(`/files/${path}`);
  }
}

export default new FileUploadService4VideoFilter();