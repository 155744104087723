import { motion } from 'framer-motion';
import intl from 'react-intl-universal';
import { Helmet } from 'react-helmet-async';

import { APP_NAME } from "../constants";

const TermsOfService = () => {

  return (
    <motion.div className="content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Helmet>
        <title>{APP_NAME} { intl.get('Terms_of_Service') }</title>
        <meta name="description" content={ intl.get('APP_DESCRIPTION') } />
      </Helmet>
      <div className="title">{ intl.get('Terms_of_Service') }</div>
      <div className="introduction">
      <p>Our <a href="/PrivacyPolicy">Privacy Policy</a> explains how we collect and use your information while our Terms of Service
outline your responsibilities when using our Service. By using our Service, you’re agreeing to be
bound by these Terms. If you’re using our Service for an organization, you’re agreeing to
these Terms on behalf of that organization.</p>
<h5 className="mb-4">Your Stuff &amp; Your Permissions</h5>
<p>When you use our Service, you provide us with things like your files, content, email address
and so on (“Your Stuff”). Your Stuff is yours. These Terms don’t give us any rights to Your
Stuff except for the limited rights that enable us to offer the Service.</p>
<p>We need your permission to do things like hosting Your Stuff, backing it up, and sharing it
when you ask us to. These and other features may require our systems to access, store Your
Stuff. You give us permission to do those things, and this permission extends to trusted third
parties we work with.</p>
<h5 className="mb-4">Your Responsibilities</h5>
<p>Please safeguard your password to the Service, make sure that others do not have access to
it, and keep your account information current. You agree not to misuse the Service. For
example, you must not attempt to do the following:
</p>
<ul>
<li>Probe, scan, or test the vulnerability of any system or network;
</li>
<li>Access, tamper with, or use non-public areas or parts of the
Service, or shared areas of the Service you haven’t been invited to;</li>
<li>Sell the Service or Service accounts via unauthorized channels;</li>
<li>Use automated or other means to create accounts in bulk or to access the Service other
than by using our official interface and/or APIs.</li>
</ul>
<h5 className="mb-4">Software</h5>
<p>Our Service may allow you to download client software (“Software”) which may update
automatically. So long as you comply with these Terms, we give you a limited, nonexclusive,
nontransferable, revocable license to use the Software, solely to access the Service. To the
extent any component of the Software may be offered under an open-source license, we’ll make
that license available to you and the provisions of that license may expressly override some
of these Terms. Unless the following restrictions are prohibited by law, you agree not to
reverse engineer or decompile the Software, attempt to do so, or assist anyone in doing so.
</p>
<p>We welcome feedback but note that we may use comments or suggestions without any obligation
to you.</p>
<h5 className="mb-4">Intellectual Property</h5>
<p>All intellectual property in the Service protectable in any jurisdiction worldwide is and will remain the exclusive property of aiTransformer and any licensors to aiTransformer or third-party developers, if applicable. 
Users may only use aiTransformer’s trademarks and trade dress in accordance with these Terms, and may not otherwise use aiTransformer’s trademarks or trade dress in connection with any product or service without the prior written consent of aiTransformer. 
Users own all artworks created by users with assistance of the Service, including all related copyrights and other intellectual property rights (if applicable). Users must, as individuals or in a group, contribute creative expression in conjunction with 
use of the Service, such as in creating or selecting prompts or user inputs to use with the tools offered by the Service. Users acknowledge that artworks generated without creative expression from the user may not be eligible for copyright protection. 
Regardless of the creativity of users, aiTransformer cannot guarantee the uniqueness, originality, or quality, or the availability or extent of copyright protection for any artwork created by users with assistance of the Service. 
You hereby grant aiTransformer a worldwide, non-exclusive, non-sublicensable, royalty-free license to copy, reproduce, and display artworks you create using the Service for promotional purposes on the Service.
</p>
<h5 className="mb-4">Accounts</h5>
<p>When you create an account with us, you must provide us with information that is accurate,
complete, and current at all times. Failure to do so constitutes a breach of the Terms, which
may result in immediate termination of your account on our Service.
</p>
<p>You are responsible for safeguarding the password that you use to access the Service and for
any activities or actions under your password, whether your password is with our Service or a
third-party service. You agree not to disclose your password to any third party. You must
notify us immediately upon becoming aware of any breach of security or unauthorized use of
your account.</p>
<h5 className="mb-4">Liability for our Service</h5>
<p>When permitted by law, we will not be responsible for lost profits, revenues, or data,
financial losses or indirect, special, consequential, exemplary, or punitive damages. The
total liability of us is limited to the amount you paid us to use the Service.</p>
<h5 className="mb-4">Links to Other Sites</h5>
<p>Our Service may contain links to third-party web sites or services that are not owned or
controlled by us. We have no control over, and assume no responsibility for, the content, privacy
policies, or practices of any third-party web sites or services. You further acknowledge and
agree that we shall not be responsible or liable, directly or indirectly, for any damage
or loss caused or alleged to be caused by or in connection with use of or reliance on any such
content, goods or services available on or through any such web sites or services.</p>
<p>We strongly advise you to read the terms and conditions and privacy policies of any
third-party web sites or services that you visit.</p>
<h5 className="mb-4">Termination</h5>
<p>You’re free to stop using our Service at any time. We also reserve the right to suspend or
end the Service at any time at our discretion and without notice. For example, we may suspend
or terminate your use of the Service if you’re not complying with these Terms, or use the
Service in a manner that would cause us legal liability, disrupt the Service or disrupt
others’ use of the Service. Except for Paid Accounts, we reserve the right to terminate and
delete your account if you haven’t accessed our Service for 12 consecutive months. We’ll of
course provide you with notice via the email address associated with your account before we do
so.
</p>
<h5 className="mb-4">Contact Us</h5>
<p>If you have any questions or suggestions about our Terms of Service, do not hesitate to contact
us at info@aitransformer.net.</p>
</div>
    </motion.div>
    );
};

export default TermsOfService;