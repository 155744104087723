import React, { Component } from "react";
import { motion } from 'framer-motion';
import parse from "html-react-parser";
import queryString from "query-string";
import intl from 'react-intl-universal';
import { Helmet } from 'react-helmet-async';

import UploadService from "../services/file-upload-stylizer.service";
import { resizeImage, getSpecialMessageHTML } from "../utils";
import { Context } from "../Context";
import { APP_NAME, REGION_TO_APPLY_OPTIONS, STYLE_OPTIONS, STYLE_MODE_OPTIONS } from "../constants";
import { PopupShare } from "./Share/share.component";

export default class UploadImages4Stylizer extends Component {
  static contextType = Context;
  timer = null;
  duration = 50 * 1000; 
  
  constructor(props) {
    super(props);
    this.selectSourceFile = this.selectSourceFile.bind(this);
    this.selectStyleFile = this.selectStyleFile.bind(this);
    //this.uploadImages = this.uploadImages.bind(this);
    this.onChangeOfSelectStyle = this.onChangeOfSelectStyle.bind(this);
    const queryParams = queryString.parse(window.location.search);

    this.state = {
      sourceFile: undefined,
      previewSourceImage: undefined,
      styleFile: undefined,
      previewStyleImage: undefined,
      mode: "0",
      progress: 0,
      message: "",

      showStyleFileBrowser: false,
      regionToApply: "all",
      watermark: window.location.pathname.endsWith("nwm") ? false : true,
      resultSizeType: queryParams.resultSizeType,
  
      resultsFolder: undefined,
      imageInfos: [],

      startTime: undefined,
    };
  }

  componentDidMount() {
    if (this.state.resultsFolder !== undefined) {
      UploadService.getFiles(this.state.resultsFolder).then((response) => {
        this.setState({
          imageInfos: response.data,
        });
      }); 
    }
  }

  componentWillUnmount() {
    this.timer = null;
  }

  selectSourceFile = async event => {
    let currentFile = event.target.files[0];
    if (currentFile.size > 2097152 || currentFile.size < 100) {  //no need to resize when size < 2M, and a too small file is probably invalid
      try {
        currentFile = await resizeImage(currentFile);
      } catch(err) {
        this.setState({
          sourceFile: undefined,
          previewSourceImage: undefined,
          progress: 0,
          message: intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
        });
        return;
      }
    }
  
    this.setState({
      sourceFile: currentFile,
      previewSourceImage: URL.createObjectURL(currentFile),
      progress: 0,
      message: ""
    });
  }

  onChangeOfRegionToApply = event => {
    this.setState({
      regionToApply: event.target.value,
      progress: 0,
      message: ""
    });
  }

  onChangeOfMode = event => {
    this.setState({
      mode: event.target.value,
      styleFile: "unselected",
      previewStyleImage: undefined,
      showStyleFileBrowser: false,
      progress: 0,
      message: "",
    });
  }

  selectStyleFile = async event => {
    let currentFile = event.target.files[0];
    if (currentFile.size > 2097152 || currentFile.size < 100) {  //no need to resize when size < 2M, and a too small file is probably invalid
      try {
        currentFile = await resizeImage(currentFile);
      } catch(err) {
        this.setState({
          styleFile: undefined,
          previewStyleImage: undefined,
          progress: 0,
          message: intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
        });
        return;
      }
    }
  
    this.setState({
      styleFile: currentFile,
      previewStyleImage: URL.createObjectURL(currentFile),
      progress: 0,
      message: ""
    });
  }

  onChangeOfSelectStyle = event => {
    if (event.target.value === "unselected" || event.target.value === "custom") {
      this.setState({
        styleFile: undefined,
        previewStyleImage: undefined,
        progress: 0,
        message: "",
        showStyleFileBrowser: event.target.value === "custom"
      });
      return;
    }
    const fileName = `${event.target.value}.jpg`
    const imageUrl = `/images/${fileName}`
    fetch(imageUrl)
      .then(response => response.blob())
      .then(imageBlob => {
        const iamgeFile = new File([imageBlob], fileName);
        this.setState({
          styleFile: iamgeFile,
          previewStyleImage: URL.createObjectURL(iamgeFile),
          progress: 0,
          message: "",
          showStyleFileBrowser: false
        })
      });
  } 

  uploadAll(files, sid, locale) {
    this.timer = null;
    if (this.state.mode === '0')
      this.duration = 35 * 1000;
    else if (this.state.mode === '1')
      this.duration = 6 * 60 * 1000;
    else
      this.duration = 60 * 1000;
    this.setState({
      progress: 0,
      startTime: Date.now(),
    });

    UploadService.upload(files, this.state.regionToApply, this.state.mode, this.state.watermark, this.state.resultSizeType, sid, locale, (event) => {
      //let portion = 20;
      //if (this.state.mode === '1')
      //  portion = 4;
      //this.setState({
      //  progress: Math.round((portion * event.loaded) / event.total),
      //});
      if (this.timer === null)
        this.timer = setInterval(() => {
          const percentage = Math.round(((Date.now() - this.state.startTime) / this.duration) * 100);
          if (percentage > 96) 
            clearInterval(this.timer);
          else 
            this.setState({ 
              progress: percentage
            })
        }, 500)
    }).then((response) => {
        clearInterval(this.timer);
        this.setState({
          message: response.data.message,
          progress: 100,
          resultsFolder: response.data.userFolder4Stylizer,
        });
      })
      .catch((reason) => {
        clearInterval(this.timer);
        if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
          this.setState({
            progress: 0,
            message: intl.getHTML('ERROR_MESSAGE_KNOWN_REASON', { reason: reason.response.data.message }),
            resultsFolder: reason.response.data.userFolder4Stylizer,
          });
        } else {
          this.setState({
            progress: 0,
            message: intl.getHTML('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
          });
        }
      })
      .finally(() => {
        if (typeof this.state.resultsFolder !== "undefined")
          UploadService.getFiles(this.state.resultsFolder)
          .then((processedFiles) => {
            this.setState({
              imageInfos: processedFiles.data,
            });
          })
      });
  }

  uploadImages(sid, locale) {
    if (this.state.sourceFile === undefined) {
      this.setState({
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_NO_FILE_SELECTED'),
      });
      return;
    }
    if (this.state.mode === "0" && this.state.styleFile === undefined) {
      this.setState({
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_NO_STYLE_SELECTED1'),
      });
      return;
    }
    this.setState(
      {
        progress: 0,
        message: ""
      },
      () => {
        if (this.state.mode > "0" ) {
          let styleFiles = STYLE_OPTIONS.slice(2);
          if (this.state.mode > "1") {
            const intMode = parseInt(this.state.mode);
            styleFiles = styleFiles.slice((intMode - 2) * 10, (intMode - 1) * 10);
          }
          let selectedFiles = styleFiles.map(async function(item) { 
            const fileName = `${item.value}.jpg`;
            const imageUrl = `/images/${fileName}`;
            let res = await fetch(imageUrl);
            return new File([await res.blob()], fileName, {type: "image/jpeg"});
          });
          Promise.all(selectedFiles)
            .then(files => {
              files.splice(0, 0, this.state.sourceFile);
              this.uploadAll(files, sid, locale);
            });
           
        } else {
          const selectedFiles = [];
          selectedFiles.push(this.state.sourceFile);
          selectedFiles.push(this.state.styleFile);
          this.uploadAll(selectedFiles, sid, locale);
        }
      }
    );
  }

  render() {
    const { dynamicText, authenticated, sid, locale, user } = this.context;
    const { sourceFile, styleFile, previewSourceImage, previewStyleImage, mode, progress, message, showStyleFileBrowser, regionToApply, imageInfos } = this.state;
    const ulClassName = this.props.isLargeScreen ? "card-deck" : "list-group list-group-flush";
    const specialMessage = getSpecialMessageHTML(authenticated, user, dynamicText);

    return (
      <motion.div className="content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <Helmet>
          <title>{APP_NAME} { intl.get('Stylizer') }</title>
          <meta name="description" content={ intl.get('Stylizer_info') } />
        </Helmet>
        <div className="title">{ intl.get('Stylizer') }</div>
        {specialMessage && (
          <div className="alert alert-danger mt-2">
            {parse(specialMessage)}
          </div>
        )}

        {(!authenticated || user.displayMode === '1') && (
          <div className="row m-1">
            <div className="col-12 text-center">
              <div className="introduction">
                { intl.getHTML('Stylizer_description') }
              </div>
              <img src="/images/stylizer2.jpg" alt="Stylizer example 2" className="img-fluid" />
            </div>
          </div>
        )}

        {this.state.watermark && !authenticated && (
          <div className="row highlight m-2">
            <div className="col-12">
              {/* intl.getHTML('MESSAGE_IMAGE_WITH_WATERMARKS') */} { intl.get('MESSAGE_TOOL_NOT_FUNCTIONAL') }
            </div>
          </div>
        )}

        <div className="row m-2">
          <div className="col-12">
            { intl.get('Stylizer_instruction') } { intl.get('download_results_message') }
          </div>
        </div>

        <div className="row m-2">
          <div className="col-12">
            { intl.get('Source_Image') }&nbsp; &nbsp;
            <label className="btn mb-3 mr-3 btn-light">
              <input type="file" accept="image/*" onChange={this.selectSourceFile} />
            </label>
          </div>
        </div>

        {previewSourceImage && (
          <div className="row m-2">
            <div className="col-12">
              <img className="preview" src={previewSourceImage} alt="" />
            </div>
          </div>
        )}
        
        <div className="row m-2">
          <div className="col-md-3">
            { intl.get('Apply_Styles_To_Region') }
          </div>
          <div className="col-md-9">
            <select className="selectpicker form-control" data-style="btn-success" onChange={this.onChangeOfRegionToApply}>
              {REGION_TO_APPLY_OPTIONS.map(item => {
                return (<option key={item.value} value={item.value} defaultValue={regionToApply}>{ intl.get('REGION_TO_APPLY_OPTIONS_' + item.value) }</option>);
              })}
            </select>
          </div>
        </div>

        <div className="row m-2">
          <div className="col-md-3">
            { intl.get('Generation_Mode') }
          </div>
          <div className="col-md-9">
            <select className="selectpicker form-control" data-style="btn-success" onChange={this.onChangeOfMode}>
              {STYLE_MODE_OPTIONS.map(item => {
                return (<option key={item.value} value={item.value} defaultValue={mode}>{ intl.get('STYLE_MODE_OPTIONS_' + item.value) }</option>);
              })}
            </select>
          </div>
        </div>

        {mode < "1" && (
          <div className="row m-2">
            <div className="col-md-3">
              { intl.get('Style_Image') }
            </div>
            <div className="col-md-9">
              <select className="selectpicker form-control" data-style="btn-success" onChange={this.onChangeOfSelectStyle}>
                {STYLE_OPTIONS.map(item => {
                  return !['unselected', 'custom'].includes(item.value) ? (<option key={item.value} value={item.value} defaultValue={styleFile}>{item.text}</option>) :
                  (<option key={item.value} value={item.value} defaultValue={styleFile}>{ intl.get('STYLE_OPTIONS_' + item.value) }</option>);
                  })}
              </select>
              {showStyleFileBrowser && (
                <label className="btn mb-3 mr-3 btn-light">
                  <input type="file" accept="image/*" onChange={this.selectStyleFile} />
                </label>
              )}
              {previewStyleImage && (
                <div>
                  <img className="preview" src={previewStyleImage} alt="" />
                </div>
              )}
            </div>
          </div>
        )}

        <div className="row m-2">
          <div className="col-12 text-center">
            <button
              className="btn mb-3 mr-3 btn-info btn-lg"
              disabled={!(sourceFile && (mode >= "1" || styleFile) && !progress)}
              onClick={() => this.uploadImages(sid, locale)}
              >
              { intl.get('UPLOAD_TO_PROCESS') }
            </button>
          </div>
        </div>

        {sourceFile && (mode >= "1" || styleFile) && (
          <div className="progress m-2">
            <div
              className="progress-bar progress-bar-info progress-bar-striped"
              role="progressbar"
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: progress + "%" }}
            >
              {progress}%
            </div>
          </div>
        )}

        {message && (
          <div className="alert alert-warning my-1 text-wrap" role="alert">
            {message}
          </div> 
        )}

        {imageInfos.length > 0 && (
          <div className="card">
            <div className="card-header alert alert-success text-center">
              <h4>
                { intl.get('Results') }&nbsp;&nbsp;
                <PopupShare title={ intl.get('HEADLINE') } uri={window.location.pathname} tags={[intl.get('HEADLINE').replace(" ", "")]}/> 
              </h4> 
              { (authenticated && user.subscriptionPlanId) ? '' : intl.getHTML('Choose_paid_plan3') }
            </div>
            <ul className={ulClassName}>
              {imageInfos.map((img, index) => (
                <li className="list-group-item" key={index}>
                  <img className="card-img-top" src={img.url} alt={img.name} />
                  <h6 className="card-title">{img.name}&nbsp;&nbsp;<a href={img.url}><b>{ intl.get('Download') }</b></a></h6>
                </li>
              ))}
            </ul>
          </div>
        )}
      </motion.div>
    );
  }
}
