import React, { useState, useEffect } from "react";
import { Preferences } from '@capacitor/preferences';
import { SERVER_API_BASE_URL, SERVER_API_BASE_URL6, useNewServer4SpeechSynthesizer } from "./constants";

export const Context = React.createContext();
export const ContextProvider = ({ children }) => {
	//const [specialMessage, setSpecialMessage] = useState(undefined);
  //const uri = `${new URL(SERVER_API_BASE_URL).origin}/assets/specialMessage.json`;  not working for Android?
  //const uri = `${SERVER_API_BASE_URL}/specialMessage`;
/*
  useEffect(() => {  //this should only run once to get specialMessage
    fetch(uri)
      .then(response => response.json())
      .then(message => {
        if (message.code > '0') {
          setSpecialMessage(message.text);
        } else {
          setSpecialMessage("");
        }
      })
      .catch(err => {
        setSpecialMessage("Unable to reach the server, please check your internet connection and try again.");
      })
    }, []);
*/  

  // Find locale from preference (local storage) and browser settings
  const [locale, setLocale] = useState('');
  useEffect(() => {  
    Preferences.get({key: 'locale'}).then(result => {
      if (result.value) {
        setLocale(result.value);
      } else {
        if (navigator.language.includes('fr'))
          setLocale('fr-FR');
        else if (navigator.language.includes('es'))
          setLocale('es-ES');
        else
          setLocale('en-US');
        //Preferences.set({key: 'locale', value: locale});
      }
    });
  }, []);

  const [dynamicText, setDynamicText] = useState({});
  useEffect(() => {  //get dynamicText from the server once
    fetch(`${SERVER_API_BASE_URL}/dynamicText?locale=${locale}`)
      .then(response => response.json())
      .then(dynamicText => {
        setDynamicText(dynamicText);
      })
  }, [locale]);
    
  // Get authenticated user data from the server once
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  const [sid, setSid] = useState('');
  useEffect(() => {  
    fetch(`${SERVER_API_BASE_URL}/auth/login/success`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true
      }
    })      
    .then(response => {
      if (response.status === 200) return response.json();
      throw new Error("failed to authenticate user");
    })
    .then(responseJson => {
      setAuthenticated(true);
      setUser(responseJson.user);
      setSid(responseJson.sid);
    })
  }, []);
  /*
  if (authenticated && user && user.displayName && dynamicText) {
    if (typeof dynamicText.specialMessage === 'undefined' || dynamicText.specialMessage.length === 0) 
      dynamicText.specialMessage = 'Welcome <b>' + user.displayName + '</b>! <a href="/MyAccount">My Account</a>';
    else if (dynamicText.specialMessage.indexOf(user.displayName) === -1)
      dynamicText.specialMessage = 'Welcome <b>' + user.displayName + '</b>! <a href="/MyAccount">My Account</a><br/>' + dynamicText.specialMessage;
  }
  */
  // Get stableDiffusionPrompts data from the server once
  const [stableDiffusionPrompts, setStableDiffusionPrompts] = useState([]);
  useEffect(() => {  
    fetch(`${SERVER_API_BASE_URL}/allPrompts`)
      .then(response => response.json())
      .then(data => {
        setStableDiffusionPrompts(data);
      })
  }, []);

  const server4SpeechSynthesizer = SERVER_API_BASE_URL;   //useNewServer4SpeechSynthesizer ? SERVER_API_BASE_URL6 : SERVER_API_BASE_URL;
  // Get ttsLanguages data from the server once
  const [ttsLanguages, setTtsLanguages] = useState([]);
  useEffect(() => {  
    fetch(`${server4SpeechSynthesizer}/allTtsLanguages`)
      .then(response => response.json())
      .then(data => {
        setTtsLanguages(data);
      })
  }, []);

  // Get ttsVoices data from the server once
  const [ttsVoices, setTtsVoices] = useState([]);
  useEffect(() => {  
    fetch(`${server4SpeechSynthesizer}/allTtsVoices`)
      .then(response => response.json())
      .then(data => {
        setTtsVoices(data);
      })
  }, []);

  // Get defaultPresenters data from the server once
  const [defaultPresenters, setDefaultPresenters] = useState([]);
  useEffect(() => {  
    fetch(`${server4SpeechSynthesizer}/defaultPresenters`)
      .then(response => response.json())
      .then(data => {
        setDefaultPresenters(data);
      })
  }, []);

  // Get backgroundImages data from the server once
  const [backgroundImages, setBackgroundImages] = useState([]);
  useEffect(() => {  
    fetch(`${server4SpeechSynthesizer}/backgroundImages`)
      .then(response => response.json())
      .then(data => {
        setBackgroundImages(data);
      })
  }, []);

  // Get usageCount from the server once
  const [usageCount, setUsageCount] = useState(88888);
  useEffect(() => {  
    fetch(`${SERVER_API_BASE_URL}/usageCount`)
      .then(response => response.json())
      .then(data => {
        setUsageCount(Number(data));
      })
  }, []);

  return (
		<Context.Provider value={{ dynamicText, authenticated, user, sid, stableDiffusionPrompts, ttsLanguages, ttsVoices, defaultPresenters, backgroundImages, locale, usageCount, setLocale }}>
			{children}
		</Context.Provider>
	);
};
