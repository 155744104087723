import { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Lightbox from 'react-image-lightbox';
import parse from "html-react-parser";
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import intl from 'react-intl-universal';
import { Helmet } from 'react-helmet-async';

import { Context } from "../Context";
import { APP_NAME, SERVER_API_BASE_URL } from "../constants";
import { getSpecialMessageHTML } from "../utils";

const Gallery = () => {
  const { authenticated, user, dynamicText } = useContext(Context);
  const [status, setStatus] = useState('');
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {  //this should only run once
    setStatus('Loading');
    fetch(`${SERVER_API_BASE_URL}/gallery/demo`)
      .then(response => response.json())
      .then(imageInfos => {
        setImages(imageInfos);
        setIsOpen(true);
      })
      .then(()=>setStatus('Success'))
      .catch(()=>setStatus('Error'));
  }, []);

  const specialMessage = getSpecialMessageHTML(authenticated, user, dynamicText);
  const navigate = useNavigate();

  return (
    <motion.div className="content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Helmet>
        <title>{APP_NAME} { intl.get('Gallery') }</title>
        <meta name="description" content={ intl.get('Gallery_meta_description') } />
      </Helmet>
      <div className="title">{ intl.get('Gallery') }</div>
      {specialMessage && (
        <div className="alert alert-danger mt-2">
          {parse(specialMessage)}
        </div>
      )}
      <div className="row m-3" />
      <div>
        <div className="row mx-4 alert alert-info">
          <div className="col-md-4 text-center">
            <button className="btn mb-2 mr-2 btn-lg highlight1"
              onClick={() => setIsOpen(true)} >
              { intl.get('Open') + ' ' + intl.get('Demo_Transformation_Gallery') }
            </button>
          </div>
          <div className="col-md-4 text-center">
            <button className="btn mb-2 mr-2 btn-lg highlight1"
              onClick={() => navigate('/PromptBuilderSamplesGallery')} >
              { intl.get('Visit') + ' ' + intl.get('PromptBuilder_Samples_Gallery') }
            </button>
          </div>
          <div className="col-md-4 text-center">
            <button className="btn mb-2 mr-2 btn-lg highlight1"
              onClick={() => navigate('/ControlNetSamplesGallery')} >
              { intl.get('Visit') + ' ' + intl.get('ControlNet_Samples_Gallery') }
            </button>
          </div>
        </div>

        {status === 'Loading' && <div>{ intl.get('Loading') }</div>}
        {status === 'Error' && <div>{ intl.get('Error_finding_collection') }</div>}
        {isOpen && images.length > 0 && (
          <Lightbox
            imageTitle={images[photoIndex].imageTitle}
            imageCaption={images[photoIndex].imageCaption}
            mainSrc={images[photoIndex].url}
            nextSrc={images[(photoIndex + 1) % images.length].url}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].url}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          />
        )}
      </div>
    </motion.div>
    );
};

export default Gallery;