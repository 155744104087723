import React, { Component } from "react";
import { motion } from 'framer-motion';
import parse from "html-react-parser";
import queryString from "query-string";
import intl from 'react-intl-universal';
import { Helmet } from 'react-helmet-async';

import UploadService from "../services/file-upload-multistylizer.service";
import { resizeImage, getSpecialMessageHTML } from "../utils";
import { Context } from "../Context";
import { APP_NAME, REGION_TO_APPLY_OPTIONS, STYLE_OPTIONS, MULTISTYLE_MODE_OPTIONS } from "../constants";
import { PopupShare } from "./Share/share.component";

export default class UploadImages4MultiStylizer extends Component {
  static contextType = Context;
  timer = null;
  duration = 50 * 1000; 
  
  constructor(props) {
    super(props);
    this.selectSourceFile = this.selectSourceFile.bind(this);
    //this.uploadImages = this.uploadImages.bind(this);
    this.selectStyleFile = this.selectStyleFile.bind(this);
    this.onChangeOfSelectStyle = this.onChangeOfSelectStyle.bind(this);
    const queryParams = queryString.parse(window.location.search);

    this.state = {
      sourceFile: undefined,
      previewSourceImage: undefined,
      styleFiles: ["unselected", "unselected", "unselected"],
      previewStyleImages: [undefined, undefined, undefined],
      showStyleFileBrowsers: [false, false, false],
      mode: "0",
      progress: 0,
      message: "",

      resultSizeType: queryParams.resultSizeType,

      resultsFolder: undefined,
      imageInfos: [],

      startTime: undefined,
      regionToApply: "all",
      watermark: (window.location.pathname.endsWith("nwm") || window.location.pathname.endsWith("nwm_hd")) ? false : true,
      largePrint: (window.location.pathname.endsWith("hd") || window.location.pathname.endsWith("nwm_hd")) ? true : false,
    };
  }

  componentDidMount() {
    if (this.state.resultsFolder !== undefined) {
      UploadService.getFiles(this.state.resultsFolder).then((response) => {
        this.setState({
          imageInfos: response.data,
        });
      }); 
    }
  }

  componentWillUnmount() {
    this.timer = null;
  }

  selectSourceFile = async event => {
    let currentFile = event.target.files[0];
    if (currentFile.size > 2097152 || currentFile.size < 100) {  //no need to resize when size < 2M, and a too small file is probably invalid
      try {
        currentFile = await resizeImage(currentFile);
      } catch(err) {
        this.setState({
          sourceFile: undefined,
          previewSourceImage: undefined,
          progress: 0,
          message: intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
        });
        return;
      }
    }
  
    this.setState({
      sourceFile: currentFile,
      previewSourceImage: URL.createObjectURL(currentFile),
      progress: 0,
      message: ""
    });
  }

  onChangeOfRegionToApply = event => {
    this.setState({
      regionToApply: event.target.value,
      progress: 0,
      message: ""
    });
  }

  onChangeOfMode = event => {
    this.setState({
      mode: event.target.value,
      styleFiles: ["unselected", "unselected", "unselected"],
      previewStyleImages: [undefined, undefined, undefined],
      showStyleFileBrowsers: [false, false, false],
      progress: 0,
      message: "",
    });
  }

  selectStyleFile = (style_num) => async event => {
    let currentFile = event.target.files[0];
    if (currentFile.size > 2097152 || currentFile.size < 100) {  //no need to resize when size < 2M, and a too small file is probably invalid
      try {
        currentFile = await resizeImage(currentFile);
      } catch(err) {
        this.setState(state => {
          const styleFileList = state.styleFiles.map((item, i) => {
            if (i === style_num) {
              return undefined;
            } else {
              return item;
            }
          });
          const previewStyleImageList = state.previewStyleImages.map((item, i) => {
            if (i === style_num) {
              return undefined;
            } else {
              return item;
            }
          });
    
          return {
            styleFiles: styleFileList,
            previewStyleImages: previewStyleImageList,
            progress: 0,
            message: intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
          };
        });
        return;
      }
    }
  
    this.setState(state => {
      const styleFileList = state.styleFiles.map((item, i) => {
        if (i === style_num) {
          return currentFile;
        } else {
          return item;
        }
      });
      const previewStyleImageList = state.previewStyleImages.map((item, i) => {
        if (i === style_num) {
          return URL.createObjectURL(currentFile);
        } else {
          return item;
        }
      });

      return {
        styleFiles: styleFileList,
        previewStyleImages: previewStyleImageList,
        progress: 0,
        message: ""
      };
    });
  }

  onChangeOfSelectStyle = (style_num) => event => {
    if (event.target.value === "unselected" || event.target.value === "custom") {
      this.setState(state => {
        const styleFileList = state.styleFiles.map((item, i) => {
          if (i === style_num) {
            return undefined;
          } else {
            return item;
          }
        });
        const previewStyleImageList = state.previewStyleImages.map((item, i) => {
          if (i === style_num) {
            return undefined;
          } else {
            return item;
          }
        });
        const showStyleFileBrowserList = state.showStyleFileBrowsers.map((item, i) => {
          if (i === style_num) {
            return event.target.value === "custom";
          } else {
            return item;
          }
        });
  
        return {
          styleFiles: styleFileList,
          previewStyleImages: previewStyleImageList,
          showStyleFileBrowsers: showStyleFileBrowserList,
          progress: 0,
          message: ""
        };
      });
      return;
    }

    const fileName = `${event.target.value}.jpg`
    const imageUrl = `/images/${fileName}`
    fetch(imageUrl)
      .then(response => response.blob())
      .then(imageBlob => {
        const iamgeFile = new File([imageBlob], fileName);
        this.setState(state => {
          const styleFileList = state.styleFiles.map((item, i) => {
            if (i === style_num) {
              return iamgeFile;
            } else {
              return item;
            }
          });
          const previewStyleImageList = state.previewStyleImages.map((item, i) => {
            if (i === style_num) {
              return URL.createObjectURL(iamgeFile);
            } else {
              return item;
            }
          });
          const showStyleFileBrowserList = state.showStyleFileBrowsers.map((item, i) => {
            if (i === style_num) {
              return false;
            } else {
              return item;
            }
          });
    
          return {
            styleFiles: styleFileList,
            previewStyleImages: previewStyleImageList,
            showStyleFileBrowsers: showStyleFileBrowserList,
            progress: 0,
            message: ""
          };
        });
      });
  } 

  uploadAll(files, sid, locale) {
    this.timer = null;
    if (this.state.mode === '0')
      this.duration = 50 * 1000;
    else if (this.state.mode === '1')
      this.duration = 300 * 1000;
    else if (this.state.mode === '2')
      this.duration = 360 * 1000;
    else
      this.duration = 60 * 1000;
      
    this.setState({
      progress: 0,
      startTime: Date.now(),
    });

    //UploadService.setupServer();
    UploadService.upload(files, this.state.regionToApply, this.state.mode, this.state.watermark, this.state.resultSizeType, sid, locale, (event) => {
      //let portion = 15;
      //if (this.state.mode === '1')
      //  portion = 10;
      //else if (this.state.mode === '2')
      //  portion = 3;
      //this.setState({
      //  progress: Math.round((portion * event.loaded) / event.total),
      //});
      if (this.timer === null)
        this.timer = setInterval(() => {
          const percentage = Math.round(((Date.now() - this.state.startTime) / this.duration) * 100);
          if (percentage > 96) 
            clearInterval(this.timer);
          else 
            this.setState({ 
              progress: percentage
            })
        }, 500)
    }).then((response) => {
        clearInterval(this.timer);
        this.setState({
          message: response.data.message,
          progress: 100,
          resultsFolder: response.data.userFolder4Multistylizer,
        });
      })
      .catch((reason) => {
        clearInterval(this.timer);
        if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
          this.setState({
            progress: 0,
            message: intl.getHTML('ERROR_MESSAGE_KNOWN_REASON', { reason: reason.response.data.message }),
            resultsFolder: reason.response.data.userFolder4Multistylizer,
          });
        } else {
          this.setState({
            progress: 0,
            message: intl.getHTML('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
          });
        }
      })
      .finally(() => {
        if (typeof this.state.resultsFolder !== "undefined")
          UploadService.getFiles(this.state.resultsFolder)
          .then((processedFiles) => {
            this.setState({
              imageInfos: processedFiles.data,
            });
          })
      });
  }

  uploadImages(sid, locale) {
    if (this.state.sourceFile === undefined) {
      this.setState({
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_NO_FILE_SELECTED'),
      });
      return;
    }
    if (this.state.mode < "2" && this.state.styleFiles.every(function(item) { return item === undefined })) {
      this.setState({
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_NO_STYLE_SELECTED2'),
      });
      return;
    }
    this.setState(
      {
        progress: 0,
        message: ""
      },
      () => {
        if (this.state.mode > "1" ) {
          let styleFiles = STYLE_OPTIONS.slice(2);
          if (this.state.mode > "2") {
            const intMode = parseInt(this.state.mode);
            styleFiles = styleFiles.slice((intMode - 3) * 10, (intMode - 2) * 10);
          }
          let selectedFiles = styleFiles.map(async function(item) { 
            const fileName = `${item.value}.jpg`;
            const imageUrl = `/images/${fileName}`;
            let res = await fetch(imageUrl);
            return new File([await res.blob()], fileName, {type: "image/jpeg"});
          });
          Promise.all(selectedFiles)
            .then(files => {
              files.splice(0, 0, this.state.sourceFile);
              this.uploadAll(files, sid, locale);
            });
           
        } else {
          const selectedFiles = [];
          selectedFiles.push(this.state.sourceFile);
          const uniqueStyleFilenames = [];
          for (var i = 0; i < this.state.styleFiles.length; i++) {
            const file = this.state.styleFiles[i];
            if (file && uniqueStyleFilenames.indexOf(file.name) === -1) {
              selectedFiles.push(file);
              uniqueStyleFilenames.push(file.name);
            }
          }
          this.uploadAll(selectedFiles, sid, locale);
        }
      }
    );
  }

  render() {
    const { dynamicText, authenticated, sid, locale, user } = this.context;
    const { sourceFile, styleFiles, previewSourceImage, previewStyleImages, mode, progress, message, showStyleFileBrowsers, regionToApply, imageInfos } = this.state;
    const ulClassName = this.props.isLargeScreen ? "card-deck" : "list-group list-group-flush";
    const specialMessage = getSpecialMessageHTML(authenticated, user, dynamicText);

    return (
      <motion.div className="content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <Helmet>
          <title>{APP_NAME} { intl.get('MultiStylizer') }</title>
          <meta name="description" content={ intl.get('MultiStylizer_info') } />
        </Helmet>
        <div className="title">{ intl.get('MultiStylizer') }</div>
        {specialMessage && (
          <div className="alert alert-danger mt-2">
            {parse(specialMessage)}
          </div>
        )}

        {(!authenticated || user.displayMode === '1') && (
          <div className="row m-1">
            <div className="col-12 text-center">
              <div className="introduction">
                { intl.getHTML('MultiStylizer_description') }
              </div>
              <img src="/images/multistylizer2.jpg" alt="MultiStylizer example 2" className="img-fluid" />
            </div>
          </div>
        )}
        
        {this.state.watermark && !authenticated && (
          <div className="row highlight m-2">
            <div className="col-12">
              { intl.getHTML('MESSAGE_FREE_TOOL') }
            </div>
          </div>
        )}

        <div className="row m-2">
          <div className="col-12">
            { intl.get('MultiStylizer_instruction') } { intl.get('download_results_message') }
          </div>
        </div>

        <div className="row m-2">
          <div className="col-12">
            { intl.get('Source_Image') }&nbsp; &nbsp;
           <label className="btn mb-3 mr-3 btn-light">
              <input type="file" accept="image/*" onChange={this.selectSourceFile} />
            </label>
          </div>
        </div>

        {previewSourceImage && (
          <div className="row m-2">
            <div className="col-12">
              <img className="preview" src={previewSourceImage} alt="" />
            </div>
          </div>
        )}
        
        <div className="row m-2">
          <div className="col-md-3">
            { intl.get('Apply_Styles_To_Region') }
          </div>
          <div className="col-md-9">
            <select className="selectpicker form-control" data-style="btn-success" onChange={this.onChangeOfRegionToApply}>
              {REGION_TO_APPLY_OPTIONS.map(item => {
                return (<option key={item.value} value={item.value} defaultValue={regionToApply}>{ intl.get('REGION_TO_APPLY_OPTIONS_' + item.value) }</option>);
              })}
            </select>
          </div>
        </div>

        <div className="row m-2">
          <div className="col-md-3">
            { intl.get('Generation_Mode') }
          </div>
          <div className="col-md-9">
            <select className="selectpicker form-control" data-style="btn-success" onChange={this.onChangeOfMode}>
              {MULTISTYLE_MODE_OPTIONS.map(item => {
                return (<option key={item.value} value={item.value} defaultValue={mode}>{ intl.get('MULTISTYLE_MODE_OPTIONS_' + item.value) }</option>);
              })}
            </select>
          </div>
        </div>

        {mode < "2" && styleFiles.map((element, index) => (
        <div className="row m-2">
          <div className="col-md-3">
            { intl.get('Style_Image') } {index + 1}
          </div>
          <div className="col-md-9">
            <select className="selectpicker form-control" data-style="btn-success" onChange={this.onChangeOfSelectStyle(index)}>
              {STYLE_OPTIONS.map(item => { 
                return !['unselected', 'custom'].includes(item.value) ? (<option key={item.value} value={item.value} defaultValue={styleFiles[index]}>{item.text}</option>) :
                (<option key={item.value} value={item.value} defaultValue={styleFiles[index]}>{ intl.get('STYLE_OPTIONS_' + item.value) }</option>);
              })}
            </select>
            {showStyleFileBrowsers[index] && (
              <label className="btn mb-3 mr-3 btn-light">
                <input type="file" accept="image/*" onChange={this.selectStyleFile(index)} />
              </label>
            )}
            {previewStyleImages[index] && (
              <div>
                <img className="preview" src={previewStyleImages[index]} alt="" />
              </div>
            )}
          </div>
        </div>
        ))}

        <div className="row m-2">
          <div className="col-12 text-center">
            <button
              className="btn mb-3 mr-3 btn-info btn-lg"
              disabled={!(sourceFile && (mode >= "2" || !styleFiles.every(function(item) { return item===undefined || item==="unselected" })) && !progress)}
              onClick={() => this.uploadImages(sid, locale)}
              >
              { intl.get('UPLOAD_TO_PROCESS') }
            </button>
          </div>
        </div>

        {sourceFile && (mode >= "2" || !styleFiles.every(function(item) { return item===undefined || item==="unselected" })) && (
          <div className="progress m-2">
            <div
              className="progress-bar progress-bar-info progress-bar-striped"
              role="progressbar"
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: progress + "%" }}
            >
              {progress}%
            </div>
          </div>
        )}

        {message && (
          <div className="alert alert-warning my-1 text-wrap" role="alert">
            {message}
          </div> 
        )}

        {imageInfos.length > 0 && (
          <div className="card">
            <div className="card-header alert alert-success text-center">
              <h4>
                { intl.get('Results') }&nbsp;&nbsp;
                <PopupShare title={ intl.get('HEADLINE') } uri={window.location.pathname} tags={[intl.get('HEADLINE').replace(" ", "")]}/> 
              </h4> 
              {/* (authenticated && user.subscriptionPlanId) ? '' : intl.getHTML('Choose_paid_plan3') */}
            </div>
            <ul className={ulClassName}>
              {imageInfos.map((img, index) => (
                <li className="list-group-item" key={index}>
                  <img className="card-img-top" src={img.url} alt={img.name} />
                  <h6 className="card-title">{img.name}&nbsp;&nbsp;<a href={img.url}><b>{ intl.get('Download') }</b></a></h6>
                </li>
              ))}
            </ul>
          </div>
        )}
      </motion.div>
    );
  }
}
