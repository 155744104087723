import { motion } from 'framer-motion';
import intl from 'react-intl-universal';
import { Helmet } from 'react-helmet-async';

import { APP_NAME } from "../constants";

const PrivacyPolicy = () => {

  return (
    <motion.div className="content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Helmet>
        <title>{APP_NAME} { intl.get('Privacy_Policy') }</title>
        <meta name="description" content={ intl.get('APP_DESCRIPTION') } />
      </Helmet>
      <div className="title">{ intl.get('Privacy_Policy') }</div>
      <div className="introduction">
      <p>At aiTransformer (“Company”, “we”, “us”, or “our”), we strive to bring AI assisted solutions to ordinary people.
Currently our service is provided at no cost and is intended for use as is. This page is to inform visitors regarding our
policies with the collection, use, and disclosure of personal information. If you choose to use our service, you agree to
the collection and use of information in relation to this policy. The information we collect is used for providing and
improving the service. We will not use or share your information with anyone except as described in this Privacy Policy.
The terms used here have the same meanings as in our <a href="/TermsOfService">Terms of Service</a>.</p>
<h5 className="mb-4">Information Collection and Use</h5>
<p>For a better experience, while using our Service, you may be asked to provide us with
certain personally identifiable information. We may also utilize services from third-party companies
and individuals due to the following reasons:</p>
<ul>
    <li>To facilitate our Service;</li>
    <li>To provide the Service on our behalf;</li>
    <li>To perform Service-related services; or</li>
    <li>To assist us in analyzing how our Service is used.</li>
</ul>
<p>The third party services providers may collect information used to identify you, please refer to the
privacy policy of third party service providers as appropriate.</p>
<ul>
<li>Apple Store Services</li>
<li>Google Play Services</li>
<li>Google Analysis Services</li>
<li>Google Ads Services</li>
</ul>
<p>We have some functions requiring user sign-in to use. In addition to our own authentication method, we use authentication services
from third parties such as Google and Meta, to simplify the sign-up process and also make the sign-in easier and more secure.
We only store the necessary data passed in by the third party service when you sign in through a third party account at the first time.
If you decide to stop using our Service after signing up with us directly or through a third party account, and would like your account data
to be removed from our database, please use the email or online form provided on our <a href="/Contact">Contact</a> page to request to delete your account.
Since we only provide temporary storage for other user data, such as the images/videos you upload to transform, as well as the transformed results, 
which will be automatically deleted three hours after their existence in our system; deleting your account means a complete deletion of your personal 
data collected by the app.</p>
<h5 className="mb-4">Cookies</h5>
<p>Cookies are files with a small amount of data that are commonly used as anonymous unique
identifiers. These are sent to your browser from the websites that you visit and are stored on
your device’s internal memory. Our Service does not use these “cookies” explicitly. However,
the app may use third party code and libraries that use “cookies” to collect information and
improve their services. You have the option to either accept or refuse these cookies and know
when a cookie is being sent to your device. If you choose to refuse our cookies, you may not
be able to use some parts of our Service.</p>
<h5 className="mb-4">Security</h5>
<p>We have implemented appropriate technical and organizational security measures designed to
protect the security of any personal information we process. However, please also remember that
we cannot guarantee that the internet itself is 100% secure. Although we will do our best to
protect your personal information, transmission of personal information to and from our Services
is at your own risk. You should only access the services within a secure environment.  </p>
<h5 className="mb-4">Links to Other Sites</h5>
<p>This Service may contain links to other sites. If you click on a third-party link, you will
be directed to that site. Note that these external sites are not operated by us. Therefore, we
strongly advise you to review the Privacy Policy of these websites. We have no control over and
assume no responsibility for the content, privacy policies, or practices of any third-party
sites or services.</p>
<h5 className="mb-4">Children’s Privacy</h5>
<p>We do not knowingly solicit data from or market to children under 18 years of age. By using
the Services, you represent that you are at least 18 or that you are the parent or guardian of
such a minor and consent to such minor dependent’s use of the Services. If we learn that personal
information from users less than 18 years of age has been collected, we will deactivate the account
and take reasonable measures to promptly delete such data from our records. If you become aware of
any data we have collected from children under age 18, please contact us.
</p>
<h5 className="mb-4">Changes to This Privacy Policy</h5>
<p>We may update our Privacy Policy from time to time. The changes are effective immediately after they are posted on this
page. Thus, you are advised to review this page periodically for any changes.</p>
<h5 className="mb-4">Contact Us</h5>
<p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact
us at info@aitransformer.net.</p>
</div>
    </motion.div>
    );
};

export default PrivacyPolicy;