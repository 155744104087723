import { cpuServer, gpuServer } from "../http-common";
import { SERVER_API_BASE_URL2 } from "../constants";

class FileUploadService4SuperStylizer {
  async getServerStatus() {
    //const isServer2 = gpuServer.defaults.baseURL.includes('remoteapi');
    const isServer2 = SERVER_API_BASE_URL2.includes('remoteapi');
    const res = await cpuServer.get("/checkGpuServerStatus?server=" + (isServer2 ? 'server2' : 'development'));
    if (res.data.isServerAvailable < "1")
      return -1;  //server unavailable
    if ((isServer2 && res.data.numRunningJobs > 0) || (!isServer2 && res.data.numRunningJobs > 4))
      return 0;  //server busy
    return 1;  //server normal
  }

  upload(files, styleDescription, numberOfImagesToProduce, seed, styleStrength, sid, locale, negativePrompt, img2imgMode, onUploadProgress) {
    let formData = new FormData();
    files.forEach(f => {
      formData.append("file", f);
    });
    formData.append("styleDescription", styleDescription);
    formData.append("numberOfImagesToProduce", numberOfImagesToProduce);
    formData.append("seed", seed);
    formData.append("styleStrength", styleStrength);
    formData.append("sid", sid);
    formData.append("locale", locale);
    formData.append("negativePrompt", negativePrompt);
    formData.append("img2imgMode", img2imgMode);

    return gpuServer.post("/upload4SuperStylizer", formData, {  
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
      timeout: 10 * 60 * 1000,
    });
  }

  getFiles(path) {
    return gpuServer.get(`/files/${path}`);
  }
}

export default new FileUploadService4SuperStylizer();