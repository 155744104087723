import React, { Component } from "react";
import { motion } from 'framer-motion';
import parse from "html-react-parser";
import queryString from "query-string";
import intl from 'react-intl-universal';
import { RangeStepInput } from 'react-range-step-input';
import { Helmet } from 'react-helmet-async';

import UploadService from "../services/file-upload-video-background-editor.service";
import { Context } from "../Context";
import { APP_NAME, SERVER_API_BASE_URL, MAX_VIDEO_FILE_SIZE, BACKGROUND_EDITOR_ACTIONS, FOREGROUND_LOCATIONS } from "../constants";
import { PopupShare } from "./Share/share.component";
import { resizeImage, getSpecialMessageHTML, getResultsFolder } from "../utils";

export default class UploadVideo4BackgroundEditor extends Component {
  static contextType = Context;
  timer = null;
  duration = 50 * 1000; 
  
  constructor(props) {
    super(props);
    this.selectSourceFile = this.selectSourceFile.bind(this);
    const queryParams = queryString.parse(window.location.search);

    this.state = {
      sourceFile: undefined,
      previewVideo: undefined,
      backgroundImage: undefined,
      previewBackgroundImage: undefined,
      backgroundVideo: undefined,
      previewBackgroundVideo: undefined,
      action: '0',
      location: '0',
      foregroundTransparency: 0,
      progress: 0,
      message: "",

      resultSizeType: queryParams.resultSizeType,

      resultsFolder: undefined,
      fileInfos: [],

      startTime: undefined,
    };
  }

  componentDidMount() {
    if (this.state.resultsFolder !== undefined) {
      UploadService.getFiles(this.state.resultsFolder).then((response) => {
        this.setState({
          fileInfos: response.data,
        });
      }); 
    }
  }

  componentWillUnmount() {
    this.timer = null;
  }

  selectSourceFile = async event => {
    let currentFile = event.target.files[0];
    if (currentFile.size < 100) {
      this.setState({
        sourceFile: undefined,
        previewVideo: undefined,
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
      });
      return;
    }
    if (currentFile.size > MAX_VIDEO_FILE_SIZE) {
      this.setState({
        sourceFile: undefined,
        previewVideo: undefined,
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_NO_VALID_FILE_FOUND'),
      });
      return;
    }

    this.setState({
      sourceFile: currentFile,
      previewVideo: URL.createObjectURL(currentFile),
      progress: 0,
      message: ""
    });
  }

  onChangeOfAction = event => {
    if (event.target.value === '0') {
      this.setState({
        action: event.target.value,
        backgroundImage: undefined,
        previewBackgroundImage: undefined,
        backgroundVideo: undefined,
        previewBackgroundVideo: undefined,
        progress: 0,
        message: "",
      });
    } else {
      this.setState({
        action: event.target.value,
        progress: 0,
        message: "",
      })
    }
  } 

  selectBackgroundImage = async event => {
    let currentFile = event.target.files[0];
    if (currentFile.size > 2097152 || currentFile.size < 100) {  //no need to resize when size < 2M, and a too small file is probably invalid
      try {
        currentFile = await resizeImage(currentFile);
      } catch(err) {
        this.setState({
          backgroundImage: undefined,
          previewBackgroundImage: undefined,
          progress: 0,
          message: intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
        });
        return;
      }
    }
  
    this.setState({
      backgroundImage: currentFile,
      previewBackgroundImage: URL.createObjectURL(currentFile),
      progress: 0,
      message: ""
    });
  }

  selectBackgroundVideo = async event => {
    let currentFile = event.target.files[0];
    if (currentFile.size < 100) {
      this.setState({
        backgroundVideo: undefined,
        previewBackgroundVideo: undefined,
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
      });
      return;
    }
    if (currentFile.size > MAX_VIDEO_FILE_SIZE) {
      this.setState({
        backgroundVideo: undefined,
        previewBackgroundVideo: undefined,
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_NO_VALID_FILE_FOUND'),
      });
      return;
    }

    this.setState({
      backgroundVideo: currentFile,
      previewBackgroundVideo: URL.createObjectURL(currentFile),
      progress: 0,
      message: ""
    });
  }

  onChangeOfLocation = event => {
    this.setState({
      location: event.target.value,
      progress: 0,
      message: ""
    });
  }

  onChangeOfForegroundTransparency = event => {
    this.setState({
      foregroundTransparency: event.target.value,
      progress: 0,
      message: ""
    });
  }

  recoverResults = async () => {
    this.setState({progress: 1});
    const resultsDir = await getResultsFolder('userFolder4VideoBackgroundEditor');
    if (!resultsDir) {
      this.setState({message: intl.get("No_results")});
    } else {
      this.setState({resultsFolder: resultsDir});
      this.componentDidMount();
    }
    this.setState({progress: 100});
  }

  uploadAll(files, sid, locale) {
    this.timer = null;
    let totalSize = 0;
    for (let i = 0; i < files.length; i++) {
      totalSize += files[i].size;
    }
    this.duration = Math.ceil(totalSize / (10 * 1024 * 1024)) * 10 * 60 * 1000;
      
    this.setState({
      progress: 0,
      startTime: Date.now(),
    });

    UploadService.upload(files, this.state.action, this.state.location, this.state.foregroundTransparency, this.state.resultSizeType, sid, locale, (event) => {
      if (this.timer === null)
        this.timer = setInterval(() => {
          const percentage = Math.round(((Date.now() - this.state.startTime) / this.duration) * 100);
          if (percentage > 96) 
            clearInterval(this.timer);
          else 
            this.setState({ 
              progress: percentage
            })
        }, 500)
    }).then((response) => {
        clearInterval(this.timer);
        this.setState({
          message: response.data.message,
          progress: 100,
          resultsFolder: response.data.userFolder4VideoBackgroundEditor,
        });
      })
      .catch((reason) => {
        clearInterval(this.timer);
        if (reason.code === 'ECONNABORTED') {
          // handle timeout error
          if (this.state.resultsFolder) {
            this.setState({progress: 100});
          } else {
            //this.setState({
            //  progress: 0,
            //  message: intl.getHTML('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
            //});
            this.recoverResults();
            this.setState({progress: 100});
          }
        } else {
          // handle other errors
          if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
            this.setState({
              progress: 0,
              message: intl.getHTML('ERROR_MESSAGE_KNOWN_REASON', { reason: reason.response.data.message }),
              resultsFolder: reason.response.data.userFolder4VideoBackgroundEditor,
            });
          } else {
            this.setState({
              progress: 0,
              message: intl.getHTML('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
            });
          }
        }
      })
      .finally(() => {
        if (typeof this.state.resultsFolder !== "undefined")
          UploadService.getFiles(this.state.resultsFolder)
          .then((processedFiles) => {
            this.setState({
              fileInfos: processedFiles.data,
            });
          })
      });
  }

  uploadVideo(sid, locale) {
    if (this.state.sourceFile === undefined) {
      this.setState({
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_NO_FILE_SELECTED'),
      });
      return;
    }
    if (this.state.action === '1' && this.state.backgroundImage === undefined) {
      this.setState({
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_NO_BACKGROUND_IMAGE_SELECTED'),
      });
      return;
    }
    if (this.state.action === '2' && this.state.backgroundVideo === undefined) {
      this.setState({
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_NO_BACKGROUND_VIDEO_SELECTED'),
      });
      return;
    }
    this.setState(
      {
        progress: 0,
        message: ""
      },
      () => {
        const selectedFiles = [];
        selectedFiles.push(this.state.sourceFile);
        if (this.state.action === '1')
          selectedFiles.push(this.state.backgroundImage);
        if (this.state.action === '2')
          selectedFiles.push(this.state.backgroundVideo);
        this.uploadAll(selectedFiles, sid, locale);
      }
    );
  }

  render() {
    const { dynamicText, authenticated, sid, locale, user } = this.context;
    const { sourceFile, previewVideo, backgroundImage, previewBackgroundImage, backgroundVideo, previewBackgroundVideo, action, location, foregroundTransparency, progress, message, fileInfos } = this.state;
    const specialMessage = getSpecialMessageHTML(authenticated, user, dynamicText);

    return (
      <motion.div className="content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <Helmet>
          <title>{APP_NAME} { intl.get('Video_Background_Editor') }</title>
          <meta name="description" content={ intl.get('VideoBackgroundEditor_info') } />
        </Helmet>
        <div className="title">{ intl.get('Video_Background_Editor') }</div>
        {specialMessage && (
          <div className="alert alert-danger mt-2">
            {parse(specialMessage)}
          </div>
        )}

        {(!authenticated || user.displayMode === '1') && this.props.isLargeScreen && (
          <div className="row m-1">
            <div className="col-4 align-self-center introduction">
              { intl.get('Video_BackgroundEditor_description') } { intl.getHTML('YouTube_Channel_note') } <br/>{ intl.getHTML('reserved_tool_note') }
            </div>
            <div className="col-8 text-center embed-responsive embed-responsive-16by9"> 
              <video className="embed-responsive-item" title="Video Background Editor Sample 2" type="video/mp4" controls autoPlay 
                poster={new URL(SERVER_API_BASE_URL).origin + '/assets/VideoBackgroundEditor2 - thumbnail.jpg'}
                src={new URL(SERVER_API_BASE_URL).origin + '/assets/VideoBackgroundEditor2.mp4'} />
            </div>
          </div>
        )}
        {(!authenticated || user.displayMode === '1') && !this.props.isLargeScreen && (
          <div className="row m-1">
            <div className="col-12 text-center">
              <div className="introduction">
                { intl.get('Video_BackgroundEditor_description') } { intl.getHTML('YouTube_Channel_note') } <br/>{ intl.getHTML('reserved_tool_note') }
              </div>
              <div className="embed-responsive embed-responsive-16by9"> 
                <video className="embed-responsive-item" title="Video Background Editor Sample 2" type="video/mp4" controls autoPlay 
                  poster={new URL(SERVER_API_BASE_URL).origin + '/assets/VideoBackgroundEditor2 - thumbnail.jpg'}
                  src={new URL(SERVER_API_BASE_URL).origin + '/assets/VideoBackgroundEditor2.mp4'} />
              </div>
            </div>
          </div>
        )}
        
        {!authenticated && (
          <div className="row highlight m-2">
            <div className="col-12">
              {/* intl.getHTML('Video_Tool_usage_quota_message') */} { intl.get('MESSAGE_TOOL_NOT_FUNCTIONAL') }
              </div>
          </div>
        )}

        <div className="row m-2">
          <div className="col-12">
            { intl.getHTML('Video_BackgroundEditor_instruction', { size_in_mb: MAX_VIDEO_FILE_SIZE / 1048576 }) } { intl.get('Video_procesing_note1') } { intl.get('download_results_message') }
          </div>
        </div>

        <div className="row m-2">
          <div className="col-md-12">
            <label className="btn mb-3 mr-3 btn-light">
              <input type="file" accept="video/mp4,video/x-m4v,video/*" onChange={this.selectSourceFile} />
            </label>
          </div>
        </div>
        {previewVideo && (
          <div className="mx-4 my-1">
             <video src={previewVideo} width="320" height="240" controls>
                Your browser does not support the video tag.
            </video> 
          </div>
        )}
        
        <div className="row m-2">
          <div className="col-md-3">
            { intl.get('Action') }
          </div>
          <div className="col-md-9">
            <select className="selectpicker form-control" data-style="btn-success" onChange={this.onChangeOfAction}>
              {BACKGROUND_EDITOR_ACTIONS.map(item => {
                return (<option key={item.value} value={item.value} defaultValue={action}>{ intl.get('BACKGROUND_EDITOR_ACTIONS_' + item.value) }</option>);
              })}
            </select>
          </div>
        </div>
        {action === '1' && (
          <div className="row m-2">
            <div className="col-md-3">
              { intl.get('Background_Image') }
            </div>
            <div className="col-md-9">
              <label className="btn mb-3 mr-3 btn-light">
                <input type="file" accept="image/*" onChange={this.selectBackgroundImage} />
              </label>
              {previewBackgroundImage && (
                <div>
                  <img className="preview" src={previewBackgroundImage} alt="" />
                </div>
              )}
            </div>
          </div>
        )}
        {action === '2' && (
          <div className="row m-2">
            <div className="col-md-3">
              { intl.get('Background_Video') }
            </div>
            <div className="col-md-9">
              <label className="btn mb-3 mr-3 btn-light">
                <input type="file" accept="video/mp4,video/x-m4v,video/*" onChange={this.selectBackgroundVideo} />
              </label>
              {previewBackgroundVideo && (
                <div>
                  <video src={previewBackgroundVideo} width="320" height="240" controls>
                      Your browser does not support the video tag.
                  </video> 
                </div>
              )}
            </div>
          </div>
        )}
        {((action === '1' && backgroundImage) || (action === '2' && backgroundVideo)) && (
          <div className="row m-2">
            <div className="col-md-3">
              { intl.get('Foreground_Location') }
            </div>
            <div className="col-md-9">
              <select className="selectpicker form-control" data-style="btn-success" onChange={this.onChangeOfLocation}>
                {FOREGROUND_LOCATIONS.map(item => {
                  return (<option key={item.value} value={item.value} defaultValue={location}>{ intl.get('FOREGROUND_LOCATIONS_' + item.value) }</option>);
                })}
              </select>
            </div>
          </div>
        )}
        {((action === '1' && backgroundImage) || (action === '2' && backgroundVideo)) && (
          <div className="row m-2">
            <div className="col-md-3">
              { intl.get('Foreground_Transparency') }
            </div>
            <div className="col-md-9">
              <div className="input-group mb-3">
                <RangeStepInput min={0} max={99} step={1} value={foregroundTransparency} onChange={this.onChangeOfForegroundTransparency} />
                <div className="input-group-append">
                    <span className="input-group-text ml-2">
                        {foregroundTransparency}%
                    </span>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row m-2">
          <div className="col-6 text-center">
            <button
              className="btn mb-3 mr-3 btn-info btn-lg"
              disabled={!(authenticated && sourceFile && (action === '0' || (action === '1' && backgroundImage) || (action === '2' && backgroundVideo)) && !progress)}
              onClick={() => this.uploadVideo(sid, locale)}
            >
              { intl.get('UPLOAD_TO_PROCESS') }
            </button>
          </div>
          <div className="col-6 text-center">
            <button
              className="btn mb-3 mr-3 btn-info btn-lg"
              disabled={!authenticated || (progress > 0 && progress < 100)}
              onClick={() => this.recoverResults()}
            >
              { intl.get('Recover_Results') }
            </button>
          </div>
        </div>

        {authenticated && sourceFile && (
          <div className="progress m-2">
            <div
              className="progress-bar progress-bar-info progress-bar-striped"
              role="progressbar"
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: progress + "%" }}
            >
              {progress}%
            </div>
          </div>
        )}

        {message && (
          <div className="alert alert-warning my-1 text-wrap" role="alert">
            {message}
          </div> 
        )}

        {fileInfos.length > 0 && (
          <div className="card">
            <div className="card-header alert alert-success text-center">
              <h4>
                { intl.get('Results') }&nbsp;&nbsp;
                <PopupShare title={ intl.get('HEADLINE') } uri={window.location.pathname} tags={[intl.get('HEADLINE').replace(" ", "")]}/> 
              </h4> 
              { (authenticated && user.subscriptionPlanId) ? '' : intl.getHTML('Choose_paid_plan5') }
            </div>
            <ul className="list-group list-group-flush">
              {fileInfos.map((vid, index) => (
                <li className="list-group-item" key={index}>
                  <h6 className="card-title">{vid.name}&nbsp;&nbsp;<a href={vid.url}><b>{ intl.get('Download') }</b></a></h6>
                  <div class="embed-responsive embed-responsive-4by3">
                    <video src={vid.url} width="320" height="240" controls /> 
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </motion.div>
    );
  }
}
