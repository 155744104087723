import React, { useEffect, useState, useContext } from 'react';
import "./App.css";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { AnimatePresence } from 'framer-motion';
import { Routes, Route, useLocation } from 'react-router-dom';

import { Capacitor } from '@capacitor/core';
import { initializeAds, showBannerAds } from './ads.ts';
//import GoogleAdSense from "./components/ads-web";
import { Context } from './Context';

import Navbarmenu from './components/Nav/Navbarmenu';
import Footer from './components/Footer/Footer.tsx';

import HomePage from "./components/homepage.component";
import UploadImages4Cartoonizer from "./components/images-upload-cartoonizer.component";
import UploadImages4Enhancer from "./components/images-upload-enhancer.component";
import UploadImages4Sketcher from "./components/images-upload-sketcher.component";
import UploadImages4Stylizer from "./components/images-upload-stylizer.component";
import UploadImages4MultiStylizer from "./components/images-upload-multistylizer.component";
import UploadImages4SuperStylizer from "./components/images-upload-super-stylizer.component";
import UploadImages4Enlarger from "./components/images-upload-enlarger.component";
import UploadImages4Filter from "./components/images-upload-filter.component";
import UploadImages4BackgroundEditor from "./components/images-upload-background-editor.component";
import UploadVideo4Cartoonizer from "./components/video-upload-cartoonizer.component";
import UploadVideo4Enhancer from "./components/video-upload-enhancer.component";
//import UploadVideo4Stylizer from "./components/video-upload-stylizer.component";
//import UploadVideo4MultiStylizer from "./components/video-upload-multistylizer.component";
import UploadVideo4Filter from "./components/video-upload-filter.component";
import UploadVideo4BackgroundEditor from "./components/video-upload-background-editor.component";
import PromptBuilder from "./components/prompt-builder.component";
import Gallery from "./components/gallery.component";
import EmbeddedBlogger from "./components/blog.component";
import Contact from "./components/contact.component";
import Pricing from "./components/Pricing/pricing.component";
import Login from "./components/login.component";
import Signup from "./components/signup.component";
import ActivateAccount from "./components/account-activation.component";
//import SendAccountActivationLink from "./components/send-account-activation-link.component";
import ResetPassword from "./components/password-reset.component";
import SendPasswordResetLink from "./components/send-password-reset-link.component";
import MyAccount from "./components/myaccount.component";
import PrivacyPolicy from "./components/privacy-policy";
import TermsOfService from "./components/terms-of-service";
import SpecialOffer from "./components/special-offer";
import ControlNetSamplesGallery from "./components/controlnet-samples-gallery.component";
import PromptBuilderSamplesGallery from "./components/prompt-builder-samples-gallery.component";
import SpeechSynthesizer from "./components/speech-synthesizer.component";
import StorybookMaker from "./components/storybook-maker.component";
import VideoPlusStudio from "./components/videoplus-studio.component.js";

import ReactGA from 'react-ga';
import { GA_MEASUREMENT_ID } from "./constants";

function App() {
  const { authenticated, user } = useContext(Context);

  if (Capacitor.isNativePlatform()) {  //enable AdMob for mobile platforms, website uses AdSense on the index.html
    initializeAds();
    showBannerAds();
  } else {  //initialize the Google Analytics
    ReactGA.initialize(GA_MEASUREMENT_ID);
  }

  const removeAds = () => {
    const googleAds1 = document.getElementById('GoogleAds1');
    if (googleAds1)
      googleAds1.parentNode.removeChild(googleAds1);
  }

  const location = useLocation();
  /*
  const [width, setWidth] = useState(0);
  
  useEffect(() => {
    function handleResize() {
      setWidth(document.body.clientWidth);  //window.innerWidth
    }
    
    window.addEventListener("resize", handleResize);
    
    handleResize();
    
    return () => { 
      window.removeEventListener("resize", handleResize);
    }
  }, [setWidth])
  */
  const screenSizeQuery = window.matchMedia('(min-width: 798px)');
  const [isLargeScreen, setLargeScreen] = useState(
    screenSizeQuery.matches
  )
  
  useEffect(() => {
    if (screenSizeQuery.addEventListener) {
      screenSizeQuery.addEventListener('change', e => setLargeScreen( e.matches ))
    } else {
      screenSizeQuery.addListener(e => setLargeScreen( e.matches ))
    }
  }, [screenSizeQuery]);

  return (
    <AnimatePresence exitBeforeEnter>
      {/* <Header windowWidth={width} /> */}
      <Navbarmenu isLargeScreen={isLargeScreen} isNativePlatform={Capacitor.isNativePlatform()} />

      {/* <div id="outer-container"> */}
        {/* <Nav outerContainerId={'outer-container'} pageWrapId={'page-wrap'} /> */}

        <div id="page-wrap" className="main-container">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<HomePage isLargeScreen={isLargeScreen} />} />
            <Route path="/Cartoonizer" element={<UploadImages4Cartoonizer isLargeScreen={isLargeScreen} />} />
            <Route path="/Enhancer" element={<UploadImages4Enhancer isLargeScreen={isLargeScreen} />} />
            <Route path="/Sketcher" element={<UploadImages4Sketcher isLargeScreen={isLargeScreen} />} />
            <Route path="/Stylizer">
              <Route index element={<UploadImages4Stylizer isLargeScreen={isLargeScreen} />} />
              {/* <Route path="nwm" element={<UploadImages4Stylizer isLargeScreen={isLargeScreen} />} /> */}
            </Route>
            <Route path="/MultiStylizer">
              <Route index element={<UploadImages4MultiStylizer isLargeScreen={isLargeScreen} />} />
              <Route path="hd" element={<UploadImages4MultiStylizer isLargeScreen={isLargeScreen} />} />
              {/* <Route path="nwm" element={<UploadImages4MultiStylizer isLargeScreen={isLargeScreen} />} /> */}
              {/* <Route path="nwm_hd" element={<UploadImages4MultiStylizer isLargeScreen={isLargeScreen} />} /> */}
            </Route>
            <Route path="/Enlarger">
              <Route index element={<UploadImages4Enlarger isLargeScreen={isLargeScreen} />} />
              {/* <Route path="nwm" element={<UploadImages4Enlarger isLargeScreen={isLargeScreen} />} /> */}
            </Route>
            <Route path="/SuperStylizer" element={<UploadImages4SuperStylizer isLargeScreen={isLargeScreen} />} />
            <Route path="/PromptBuilder" element={<PromptBuilder isLargeScreen={isLargeScreen} />} />
            <Route path="/Filter" element={<UploadImages4Filter isLargeScreen={isLargeScreen} />} />
            <Route path="/BackgroundEditor" element={<UploadImages4BackgroundEditor isLargeScreen={isLargeScreen} />} />
            <Route path="/VideoCartoonizer" element={<UploadVideo4Cartoonizer isLargeScreen={isLargeScreen} />} />
            <Route path="/VideoEnhancer" element={<UploadVideo4Enhancer isLargeScreen={isLargeScreen} />} />
            {/*<Route path="/VideoStylizer" element={<UploadVideo4Stylizer isLargeScreen={isLargeScreen} />} />*/}
            <Route path="/VideoFilter" element={<UploadVideo4Filter isLargeScreen={isLargeScreen} />} />
            <Route path="/VideoBackgroundEditor" element={<UploadVideo4BackgroundEditor isLargeScreen={isLargeScreen} />} />
            <Route path="/Gallery" element={<Gallery isLargeScreen={isLargeScreen} />} />
            <Route path="/Blog" element={<EmbeddedBlogger isLargeScreen={isLargeScreen} />} />
            <Route path="/Contact" element={<Contact isLargeScreen={isLargeScreen} />} />
            {/*<Route path="/SendAccountActivationLink" element={<SendAccountActivationLink isLargeScreen={isLargeScreen} />} />
            <Route path="/SpecialOffer" element={<SpecialOffer isLargeScreen={isLargeScreen} />} />*/}
            {/*
            <Route path="/Pricing" element={<Pricing isLargeScreen={isLargeScreen} />} />
            <Route path="/Login" element={<Login isLargeScreen={isLargeScreen} />} />
            <Route path="/Signup" element={<Signup isLargeScreen={isLargeScreen} />} />
            <Route path="/ActivateAccount/*" element={<ActivateAccount isLargeScreen={isLargeScreen} />} />
            <Route path="/ResetPassword/*" element={<ResetPassword isLargeScreen={isLargeScreen} />} />
            <Route path="/SendPasswordResetLink" element={<SendPasswordResetLink isLargeScreen={isLargeScreen} />} />
            <Route path="/MyAccount" element={<MyAccount isLargeScreen={isLargeScreen} />} />
            */}
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy isLargeScreen={isLargeScreen} />} />
            <Route path="/TermsOfService" element={<TermsOfService isLargeScreen={isLargeScreen} />} />
            <Route path="/ControlNetSamplesGallery" element={<ControlNetSamplesGallery isLargeScreen={isLargeScreen} />} />
            <Route path="/PromptBuilderSamplesGallery" element={<PromptBuilderSamplesGallery isLargeScreen={isLargeScreen} />} />
            <Route path="/SpeechSynthesizer" element={<SpeechSynthesizer isLargeScreen={isLargeScreen} />} />
            <Route path="/StorybookMaker" element={<StorybookMaker isLargeScreen={isLargeScreen} />} />
            <Route path="/VideoPlusStudio" element={<VideoPlusStudio isLargeScreen={isLargeScreen} />} />
          </Routes>
        </div>
      {/* </div> */}
      
      <Footer isLargeScreen={isLargeScreen} />
      {/*!(authenticated && user.subscriptionPlanId) && (
        <GoogleAdSense currentPath={location.pathname} /> 
      )*/}
      {authenticated && user.subscriptionPlanId && (
        removeAds() 
      )}
    </AnimatePresence>
  );
}

export default App;
