import React, { useState, useRef, useEffect } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import copy from 'copy-to-clipboard';
import intl from 'react-intl-universal';

import { APP_NAME, SERVER_API_BASE_URL } from "../../constants";
import controlNetSamples from "./controlNetSamples.json";

export const ControlNetSamplesView = ({enableLightbox}) => {
  const inputs = useRef([]);
  const outputs = useRef([]);
  const outputVariations = useRef([]);
  const [inputImages, setInputImages] = useState([]);
  const [outputImages, setOutputImages] = useState([]);
  const [detailOutputImages, setDetailOutputImages] = useState([]);
  const [inputImageIndex, setInputImageIndex] = useState(0);
  const [detailOutputImageIndex, setDetailOutputImageIndex] = useState(-1);

  // Sort the gallary items by id
  const compareGalleryItems = (a, b) => {
    if (a.id > b.id) return 1; 
    if (a.id < b.id) return -1; 
    return 0;
  }

  useEffect(() => { 
    inputs.current = controlNetSamples.filter(x => x.id.length === 2).sort(compareGalleryItems);
    outputs.current = controlNetSamples.filter(x => x.id.length === 4).sort(compareGalleryItems);
    outputVariations.current = controlNetSamples.filter(x => x.id.length === 6).sort(compareGalleryItems);

    const images = [];
    inputs.current.forEach((item, key) => {
      images.push({
        src: new URL(SERVER_API_BASE_URL).origin + '/assets/controlNetSamples/' + APP_NAME + '-' + item.name,
        width: item.width || 128,
        height: item.height || 128,
        thumbnailCaption: item.title,
      });
    });
    setInputImages(images);
  }, []);


  const onClickOfInputImage = (index) => {
    const nextImages = inputImages.map((image, i) =>
      i === index ? { ...image, isSelected: true } : { ...image, isSelected: false }
    );
    setInputImages(nextImages);
    setInputImageIndex(index);
    const images = [];
    const prefix = `${index + 1}`.padStart(2, '0');
    outputs.current.filter(x => x.id.startsWith(prefix)).forEach((item, key) => {
      images.push({
        src: new URL(SERVER_API_BASE_URL).origin + '/assets/controlNetSamples/' + APP_NAME + '-' + item.name,
        width: item.width || 128,
        height: item.height || 128,
        thumbnailCaption: item.title,
      });
    });
    setOutputImages(images);
  }

  if (inputImages.length > 0 && outputImages.length === 0)
    onClickOfInputImage(0);

  const onClickOfOutputImage = (index) => {
    const images = [];
    const prefix = `${inputImageIndex + 1}`.padStart(2, '0') + `${index + 1}`.padStart(2, '0');
    outputVariations.current.filter(x => x.id.startsWith(prefix)).forEach((item, key) => {
      images.push({
        src: new URL(SERVER_API_BASE_URL).origin + '/assets/controlNetSamples/' + APP_NAME + '-' + item.name,
        title: item.title,
      });
    });
    setDetailOutputImages(images);
    if (images.length > 0)
      setDetailOutputImageIndex(0); 
  }

  const onSelectOutputImage = (index) => {
    const nextImages = outputImages.map((image, i) =>
      i === index ? { ...image, isSelected: !image.isSelected } : image
    );
    setOutputImages(nextImages);
    let prompt = '';
    nextImages.forEach((item, key) => {
      if (item.isSelected)
        prompt += prompt.length > 0 ? ', ' + item.thumbnailCaption : item.thumbnailCaption;
    });
    copy(prompt);
  }

  return (
    <div>
      <div className="row m-1 introduction highlight1">
        { intl.get('Sample_input') }
      </div>
      {inputImages.length > 0 && (
        <Gallery id='input'
          images={inputImages}
          onClick={onClickOfInputImage}
          enableImageSelection={false}
        />
      )}
      <div className="row m-1 introduction highlight1">
        { enableLightbox ? intl.get('Sample_output_with_original') : intl.get('Sample_output') }
      </div>
      {outputImages.length > 0 && (
        <Gallery id='output'
          images={outputImages}
          onClick={onClickOfOutputImage}
          onSelect={onSelectOutputImage}
        />
      )}
      {enableLightbox && detailOutputImages.length > 0 && detailOutputImageIndex > -1 && (
        <Lightbox
          imageTitle={detailOutputImages[detailOutputImageIndex].title}
          mainSrc={detailOutputImages[detailOutputImageIndex].src}
          nextSrc={detailOutputImages[(detailOutputImageIndex + 1) % detailOutputImages.length].src}
          prevSrc={detailOutputImages[(detailOutputImageIndex + detailOutputImages.length - 1) % detailOutputImages.length].src}
          onCloseRequest={() => setDetailOutputImageIndex(-1)}
          onMovePrevRequest={() => setDetailOutputImageIndex((detailOutputImageIndex + detailOutputImages.length - 1) % detailOutputImages.length)}
          onMoveNextRequest={() => setDetailOutputImageIndex((detailOutputImageIndex + 1) % detailOutputImages.length)}
        />
    )}
    </div>
  );
}

