import { cpuServer, gpuServer } from "../http-common";

class FileUploadService4PromptBuilder {
  async getServerStatus() {
    const res = await cpuServer.get("/checkGpuServerStatus");
    if (res.data.isServerAvailable < "1")
      return -1;  //server unavailable
    if (res.data.hasRunningJob > "0")
      return 0;  //server busy
    return 1;  //server normal
  }

  upload(files, sid, locale, onUploadProgress) {
    let formData = new FormData();
    files.forEach(f => {
      formData.append("file", f);
    });
    formData.append("text2Image", false);
    formData.append("sid", sid);
    formData.append("locale", locale);

    return gpuServer.post("/upload4SuperStylizer", formData, {  
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }
}

export default new FileUploadService4PromptBuilder();