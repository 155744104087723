import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import intl from 'react-intl-universal';

import './Footer.scss';

const Footer = props => {
  const navigate = useNavigate();
  const { isLargeScreen } = props;
  //const iconStyle = isLargeScreen ? 'height:40, width:40' : 'height:35, width:35';

  return (
    <div className="footer">
      <div className="row my-2">
        <div className="col-xl-3">
          { intl.get('Copyright') }
        </div>
        <div className="col-xl-6">
          <button className="btn mb-2 mr-2 btn-light" onClick={() => navigate('/Contact')}>{intl.get('Contact')}</button>
          <button className="btn mb-2 mr-2 btn-light" onClick={() => navigate('/PrivacyPolicy')}>{isLargeScreen ? intl.get('Privacy_Policy') : intl.get('Privacy')}</button>
          <button className="btn mb-2 mr-2 btn-light" onClick={() => navigate('/TermsOfService')}>{isLargeScreen ? intl.get('Terms_of_Service') : intl.get('Terms')}</button>
        </div>
        <div className="col-xl-3">
          <SocialIcon style={{ height:35, width:35 }} url="https://www.facebook.com/profile.php?id=100079541616778" target="_blank" rel="noreferrer"/>&nbsp;&nbsp;
          <SocialIcon style={{ height:35, width:35 }} url="https://www.instagram.com/aitransformer/" target="_blank" rel="noreferrer"/>&nbsp;&nbsp;
          <SocialIcon style={{ height:35, width:35 }} url="https://twitter.com/aiTransformerA" target="_blank" rel="noreferrer"/>&nbsp;&nbsp;
          <SocialIcon style={{ height:35, width:35 }} url="https://www.reddit.com/r/ai_blockchain_art/" target="_blank" rel="noreferrer"/>&nbsp;&nbsp;
          <SocialIcon style={{ height:35, width:35 }} url="https://aitransformer.tumblr.com/" target="_blank" rel="noreferrer"/>&nbsp;&nbsp;
          <SocialIcon style={{ height:35, width:35 }} url="https://www.youtube.com/@aitransformer" target="_blank" rel="noreferrer"/> 
          {/* <SocialIcon style={{ height:35, width:35 }} url="https://aitransformer.blogspot.com" target="_blank" rel="noreferrer"/>&nbsp;&nbsp;
          <SocialIcon style={{ height:35, width:35 }} url="https://www.linkedin.com/in/aiblockchain-art-79846a254/" target="_blank" rel="noreferrer"/> */} 
        </div>
      </div>

    </div>
  );
};

export default Footer;
