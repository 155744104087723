import Compress from "compress.js"
import {createButton} from "react-social-login-buttons";
import {createSvgIcon} from "react-social-login-buttons";
import intl from 'react-intl-universal';
import { SERVER_API_BASE_URL } from "./constants";

export async function resizeImage(file) {
  const compress = new Compress();
  const resizedImage = await compress.compress([file], {
    size: 2, // the max size in MB, defaults to 2MB
    quality: 0.75, // the quality of the image, max is 1,
    maxWidth: 10000, // the max width of the output image, defaults to 1920px
    maxHeight: 10000, // the max height of the output image, defaults to 1920px
    resize: true // defaults to true, set false if you do not want to resize the image width and height
  })
  const img = resizedImage[0];
  const resizedFile = Compress.convertBase64ToFile(img.data, img.ext);
  return new File([resizedFile], img.alt, {type: img.ext});
}
  
export async function resizeImageLarge(file) {
  const compress = new Compress();
  const resizedImage = await compress.compress([file], {
    size: 10, // the max size in MB, defaults to 2MB
    quality: 1, // the quality of the image, max is 1,
    maxWidth: 30000, // the max width of the output image, defaults to 1920px
    maxHeight: 30000, // the max height of the output image, defaults to 1920px
    resize: true // defaults to true, set false if you do not want to resize the image width and height
  })
  const img = resizedImage[0];
  const resizedFile = Compress.convertBase64ToFile(img.data, img.ext);
  return new File([resizedFile], img.alt, {type: img.ext});
}
    
export async function resizeImageToBase64(file) {
  const compress = new Compress();
  const resizedImage = await compress.compress([file], {
    size: 2, // the max size in MB, defaults to 2MB
    quality: 0.75, // the quality of the image, max is 1,
    maxWidth: 10000, // the max width of the output image, defaults to 1920px
    maxHeight: 10000, // the max height of the output image, defaults to 1920px
    resize: true // defaults to true, set false if you do not want to resize the image width and height
  })
  const img = resizedImage[0];
  return [img.alt, img.data];
}
  
//same method can be used for images/audio too, making some little changes, only works on certain types of videos
export async function getMediaMetadata(file) {
  const fileCallbackToPromise = (fileObj) => {
    return Promise.race([
      new Promise((resolve) => {
        if (fileObj instanceof HTMLImageElement) fileObj.onload = resolve;
        else fileObj.onloadedmetadata = resolve;
      }),
      new Promise((_, reject) => {
        setTimeout(reject, 1000);
      }),
    ]);
  };

  const objectUrl = URL.createObjectURL(file);
  // const isVideo = type.startsWith('video/');
  const video = document.createElement("video");
  video.src = objectUrl;
  await fileCallbackToPromise(video);
  return {
    duration: video.duration,
    width: video.videoWidth,
    height: video.videoHeight,
  };
}

//only works on certain types of videos
export async function getVideoDuration(file) {
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const media = new Audio(reader.result);
      media.onloadedmetadata = () => resolve(media.duration);
    };
    reader.readAsDataURL(file);
    reader.onerror = error => reject(error);
  });
}

function tumblrIcon({ width, height, color }) {
  return (<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g>
      <path d="M14.563 24c-5.093 0-7.031-3.756-7.031-6.411V9.747H5.116V6.648c3.63-1.313 4.512-4.596 4.71-6.469C9.84.051 9.941 0 9.999 0h3.517v6.114h4.801v3.633h-4.82v7.47c.016 1.001.375 2.371 2.207 2.371h.09c.631-.02 1.486-.205 1.936-.419l1.156 3.425c-.436.636-2.4 1.374-4.156 1.404h-.178l.011.002z" fill={color} />
    </g>
  </svg>);
}

const tumblrConfig = {
  text: "Log in with Tumblr",
  icon: createSvgIcon(tumblrIcon),
  iconFormat: name => `fa fa-${name}`,
  style: { background: "#004C99" },
  activeStyle: { background: "#000080" }
};
/** My Tumblr login button. */
export const MyTumblrLoginButton = createButton(tumblrConfig);

function redditIcon({ width, height, color }) {
  return (<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g>
      <path d="M12 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0zm5.01 4.744c.688 0 1.25.561 1.25 1.249a1.25 1.25 0 0 1-2.498.056l-2.597-.547-.8 3.747c1.824.07 3.48.632 4.674 1.488.308-.309.73-.491 1.207-.491.968 0 1.754.786 1.754 1.754 0 .716-.435 1.333-1.01 1.614a3.111 3.111 0 0 1 .042.52c0 2.694-3.13 4.87-7.004 4.87-3.874 0-7.004-2.176-7.004-4.87 0-.183.015-.366.043-.534A1.748 1.748 0 0 1 4.028 12c0-.968.786-1.754 1.754-1.754.463 0 .898.196 1.207.49 1.207-.883 2.878-1.43 4.744-1.487l.885-4.182a.342.342 0 0 1 .14-.197.35.35 0 0 1 .238-.042l2.906.617a1.214 1.214 0 0 1 1.108-.701zM9.25 12C8.561 12 8 12.562 8 13.25c0 .687.561 1.248 1.25 1.248.687 0 1.248-.561 1.248-1.249 0-.688-.561-1.249-1.249-1.249zm5.5 0c-.687 0-1.248.561-1.248 1.25 0 .687.561 1.248 1.249 1.248.688 0 1.249-.561 1.249-1.249 0-.687-.562-1.249-1.25-1.249zm-5.466 3.99a.327.327 0 0 0-.231.094.33.33 0 0 0 0 .463c.842.842 2.484.913 2.961.913.477 0 2.105-.056 2.961-.913a.361.361 0 0 0 .029-.463.33.33 0 0 0-.464 0c-.547.533-1.684.73-2.512.73-.828 0-1.979-.196-2.512-.73a.326.326 0 0 0-.232-.095z" fill={color} />
    </g>
  </svg>);
}

const redditConfig = {
  text: "Log in with Reddit",
  icon: createSvgIcon(redditIcon),
  iconFormat: name => `fa fa-${name}`,
  style: { background: "#FF6347" },
  activeStyle: { background: "#A52A2A" }
};
/** My Reddit login button. */
export const MyRedditLoginButton = createButton(redditConfig);

export async function getBase64String(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function getSpecialMessageHTML(authenticated, user, dynamicText) {
  let specialMessage = '';
  if (authenticated && user && user.displayName && dynamicText) {
    specialMessage = intl.get('Greetings', { name: user.displayName }) + '<a href="/MyAccount">' + intl.get('My_Account') + '</a><br/>' + (dynamicText.specialMessage || '');
  } else if (dynamicText) {
    specialMessage = dynamicText.specialMessage;
  }
  return specialMessage;
}

export function isUrlValid(string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

export async function getResultsFolder(folderName) {
  return new Promise((resolve, reject) => {
    fetch(`${SERVER_API_BASE_URL}/resultsFolder/${folderName}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true
      }
    })     
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        return '';
      }
    })
    .then(data => { 
      resolve(data); 
    })
    .catch(err => {
      console.log(err);
      resolve('');
    })
  })
}

 