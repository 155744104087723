import { Capacitor } from '@capacitor/core';
import { AdMob, BannerAdOptions, BannerAdSize, BannerAdPosition, BannerAdPluginEvents, AdMobBannerSize } from '@capacitor-community/admob';

export async function initializeAds(): Promise<void> {
  AdMob.initialize({
    //requestTrackingAuthorization: true,
    //testingDevices: ['2077ef9a63d2b398840261c8221a0c9b'],
    //initializeForTesting: true,
  });
}

export async function showBannerAds(): Promise<void> {
    AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
      // Subscribe Banner Event Listener
    });

    AdMob.addListener(BannerAdPluginEvents.SizeChanged, (size: AdMobBannerSize) => {
      // Subscribe Change Banner Size
    });

    const options: BannerAdOptions = {
      adId: 'ca-app-pub-3940256099942544/6300978111',    //test id
      adSize: BannerAdSize.ADAPTIVE_BANNER,
      position: BannerAdPosition.BOTTOM_CENTER,
      margin: 0,
      // isTesting: true
      // npa: true
    };

    if (Capacitor.getPlatform() === 'android') {
      options.adId = 'ca-app-pub-5848441810774905/3300649864' 
    } else if (Capacitor.getPlatform() === 'ios') {
      options.adId = 'ca-app-pub-5848441810774905/3762000086' 
    }
    AdMob.showBanner(options);
}
