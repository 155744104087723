import { cpuServer, gpuServer } from "../http-common";

class FileUploadService4Multistylizer {
  server = cpuServer;

  async setupServer() {
    const res = await cpuServer.get("/checkGpuServerStatus");
    if (res.data.isServerAvailable > "0" && res.data.hasRunningJob < "1")
        this.server = gpuServer;
  }

  upload(files, regionToApply, mode, watermark, resultSizeType, sid, locale, onUploadProgress) {
    let formData = new FormData();
    files.forEach(f => {
      formData.append("file", f);
    });
    formData.append("regionToApply", regionToApply);
    formData.append("mode", mode);
    formData.append("watermark", watermark);
    //formData.append("largePrint", largePrint);
    formData.append("resultSizeType", resultSizeType);
    formData.append("sid", sid);
    formData.append("locale", locale);

      return this.server.post("/upload4Multistylizer", formData, {  
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles(path) {
    return this.server.get(`/files/${path}`);
  }
}

export default new FileUploadService4Multistylizer();